import styled from '@emotion/styled/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef, useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

function MainFourthSection() {
    const Title1Ref = useRef(null);
    const Title2Ref = useRef(null);
    const Chat1Ref = useRef(null);
    const Chat2Ref = useRef(null);
    const Chat3Ref = useRef(null);
    const Chat4Ref = useRef(null);
    const Chat5Ref = useRef(null);
    const FullChatRef = useRef(null);
    useEffect(() => {
        gsap.to(Title1Ref.current, 0.7, {
            transform: 'translateY(0px)',
            opacity: 1,
            scrollTrigger: {
                trigger: '.fourth-title',
                start: '30% bottom',
            },
        });

        gsap.to(Title2Ref.current, 0.7, {
            transform: 'translateY(0px)',
            opacity: 1,
            delay: 0.3,
            scrollTrigger: {
                trigger: '.fourth-title',
                start: '30% bottom',
            },
        });

        gsap.to(Chat1Ref.current, 0.2, {
            transform: 'translateY(0px)',
            opacity: 1,
            delay: 0.2,
            scrollTrigger: {
                trigger: '.chat-bubble1',
                start: '20% bottom',
            },
        });

        gsap.to(Chat2Ref.current, 0.2, {
            delay: 0.2,
            transform: 'translateY(0px)',
            opacity: 1,
            scrollTrigger: {
                trigger: '.chat-bubble2',
                start: '20% bottom',
            },
        });

        gsap.to(Chat3Ref.current, 0.2, {
            transform: 'translateY(0px)',
            delay: 0.2,
            opacity: 1,
            scrollTrigger: {
                trigger: '.chat-bubble3',
                start: '20% bottom',
            },
        });

        gsap.to(Chat4Ref.current, 0.2, {
            delay: 0.2,
            transform: 'translateY(0px)',
            opacity: 1,
            scrollTrigger: {
                trigger: '.chat-bubble4',
                start: '20% bottom',
            },
        });

        gsap.to(Chat5Ref.current, 0.2, {
            transform: 'translateY(0px)',
            opacity: 1,
            delay: 0.2,
            scrollTrigger: {
                trigger: '.chat-bubble5',
                start: '20% bottom',
            },
        });

        const tl1 = gsap.timeline({
            scrollTrigger: {
                trigger: '.chat-bubble6',
                start: 'top 80%',
                end: 'top 65%',
                scrub: 1,
            },
        });

        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: '.chat-bubble6',
                start: 'top 80%',
                end: 'top 65%',
                scrub: 1,
            },
        });

        const tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: '.chat-bubble6',
                start: 'top 80%',
                end: 'top 70%',
                scrub: 1,
            },
        });

        const tl4 = gsap.timeline({
            scrollTrigger: {
                trigger: '.chat-bubble6',
                start: 'top 80%',
                end: 'top 70%',
                scrub: 1,
            },
        });

        tl1.fromTo(
            '.chat-bubble6',
            0.2,
            {
                width: '619px',
                bottom: '0',
                right: '60px',
                padding: '35px 0 35px 30px',
                backgroundColor: ' #79FFDE',
            },
            {
                width: '1280px',
                bottom: '-224px',
                right: '-186px',
                padding: '53px 0 53px 63px',
                backgroundColor: '#D8FFF6',
            }
        );

        const SlideBox = gsap.utils.toArray('.slide-wrapper .slide-box');

        gsap.fromTo(
            '.slide-wrapper',
            { translateX: 0 },
            {
                translateX: '-731px',
                ease: 'none',
                scrollTrigger: {
                    trigger: '.chat-bubble6',
                    start: 'top 35%',
                    end: 'bottom 50%',
                    scrub: 1,
                },
            }
        );

        tl2.fromTo(
            SlideBox,
            {
                minWidth: '147px',
                height: '147px',
                marginTop: '24px',
                marginRight: '12px',
                borderRadius: '15px',
                padding: '15px',
            },
            {
                minWidth: '294px',
                height: '294px',
                marginTop: '40px',
                marginRight: '24px',
                borderRadius: '25px',
                padding: '30px',
            }
        );

        tl3.fromTo(
            '.slide-box h3',
            {
                fontSize: '14px',
                marginBottom: '8px',
            },
            {
                fontSize: '28px',
                marginBottom: '16px',
            }
        );

        tl4.fromTo(
            '.slide-box p',
            {
                fontSize: '9px',
            },
            {
                fontSize: '18px',
            }
        );
    }, []);

    return (
        <Container>
            <Section>
                <TitleWrapper className="fourth-title">
                    <TitleBox>
                        <Title ref={Title1Ref}>우리가 만드는 작은 행동 변화</Title>
                    </TitleBox>
                    <TitleBox>
                        <Title2 ref={Title2Ref}>탄소배출권</Title2>
                    </TitleBox>
                </TitleWrapper>
                <ChatWrapper>
                    <ChatBubbleLine>
                        <ChatBubbleLeft ref={Chat1Ref} className="chat-bubble1">
                            그런데..
                        </ChatBubbleLeft>
                    </ChatBubbleLine>
                    <ChatBubbleLine>
                        <ChatBubbleLeft2 ref={Chat2Ref} className="chat-bubble2">
                            탄소배출권이 무엇인가요?
                        </ChatBubbleLeft2>
                    </ChatBubbleLine>
                    <ChatBubbleLine2>
                        <ChatBubbleRight1 ref={Chat3Ref} className="chat-bubble3">
                            <strong>탄소배출권</strong>이란,
                            <br />
                            이산화탄소(CO₂) 등의 온실가스를
                            <br />
                            배출할 수 있는 <strong>‘권리’</strong>를 뜻해요.
                        </ChatBubbleRight1>
                    </ChatBubbleLine2>
                    <ChatBubbleLine2>
                        <ChatBubbleRight2 ref={Chat4Ref} className="chat-bubble4">
                            국가 또는 기업에서 <strong>탄소배출권 프로젝트</strong>를 진행하면
                            <br />
                            인증기관으로부터 그 ‘권리’를 받게 돼요.
                            <br />
                            우리는 그 <strong>‘권리’를 사고팔고, 상쇄</strong>함으로써
                            <br />
                            <strong>환경을 보호</strong>할 수 있어요.
                        </ChatBubbleRight2>
                    </ChatBubbleLine2>
                    <ChatBubbleLine>
                        <ChatBubbleLeft3 ref={Chat5Ref} className="chat-bubble5">
                            탄소배출권 프로젝트는 어떤 것들이 있나요?
                        </ChatBubbleLeft3>
                    </ChatBubbleLine>
                    {/* <ChatBubbleLine3 ref={Chat6Ref}>
                    </ChatBubbleLine3> */}
                    <ChatBubbleRight3 ref={FullChatRef} className="chat-bubble6">
                        <ChatBubbleComment>
                            <strong>탄소배출권 프로젝트</strong>는 이러한 것들이 있어요.
                        </ChatBubbleComment>
                        <ChatSlideWrapper className="slide-wrapper">
                            <ChatSlideBox className="slide-box">
                                <ChatSlideTitle>블루카본</ChatSlideTitle>
                                <ChatSlideText>
                                    바다생물의 생태계를 보호하고
                                    <br />
                                    지구의 탄소를 흡수
                                </ChatSlideText>
                            </ChatSlideBox>
                            <ChatSlideBox className="slide-box">
                                <ChatSlideTitle>수력 발전</ChatSlideTitle>
                                <ChatSlideText>
                                    물을 이용하여 친환경적으로
                                    <br />
                                    전기를 발전
                                </ChatSlideText>
                            </ChatSlideBox>
                            <ChatSlideBox className="slide-box">
                                <ChatSlideTitle>REDD+</ChatSlideTitle>
                                <ChatSlideText>
                                    산과 숲을 보호함으로써
                                    <br /> 생태계를 보존
                                </ChatSlideText>
                            </ChatSlideBox>
                            <ChatSlideBox className="slide-box">
                                <ChatSlideTitle>풍력 발전</ChatSlideTitle>
                                <ChatSlideText>
                                    바람의 힘을 활용하여
                                    <br />
                                    온실가스를 줄이고 전기를 생산
                                </ChatSlideText>
                            </ChatSlideBox>
                            <ChatSlideBox className="slide-box">
                                <ChatSlideTitle>쿡스토브</ChatSlideTitle>
                                <ChatSlideText>
                                    연료의 효율을 높이고
                                    <br />
                                    환경오염을 줄이는 조리 기구
                                </ChatSlideText>
                            </ChatSlideBox>
                            <ChatSlideBox className="slide-box">
                                <ChatSlideTitle>태양열 발전</ChatSlideTitle>
                                <ChatSlideText>
                                    태양에서 나오는 열을 이용해
                                    <br />
                                    전기를 생산
                                </ChatSlideText>
                            </ChatSlideBox>
                        </ChatSlideWrapper>
                    </ChatBubbleRight3>
                </ChatWrapper>
                {/* <EmptyPad className='empty-pad' /> */}
            </Section>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    padding-top: 160px;
`;

const Section = styled.div`
    position: relative;
    width: 1280px;
    margin: 0 auto;
`;

const TitleWrapper = styled.div`
    display: block;
`;

const TitleBox = styled.div`
    display: block;
    overflow: hidden;
`;

const Title = styled.h2`
    color: var(--default-text);
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.4;
    opacity: 0;
    transform: translateY(60px);
`;

const Title2 = styled.h2`
    color: var(--main-color);
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    line-height: auto;
    opacity: 0;
    transform: translateY(60px);
`;

const ChatWrapper = styled.div`
    position: relative;
    width: 907px;
    margin: 0 auto;
    background-color: #f6f6f6;
    border-radius: 52px;
    margin-top: 75px;
    padding: 60px;
    padding-bottom: 312px;
`;

const ChatBubbleLine = styled.div`
    display: flex;
`;
const ChatBubbleLine2 = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ChatBubbleLeft = styled.span`
    display: inline-block;
    padding: 23px 30px;
    background-color: #31449f;
    border-radius: 30px;
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    line-height: auto;
    opacity: 0;
    transform: translateY(50px);
`;

const ChatBubbleLeft2 = styled.span`
    position: relative;
    display: inline-block;
    padding: 23px 30px;
    background-color: var(--main-color);
    border-radius: 30px;
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    line-height: auto;
    margin-top: 16px;
    opacity: 0;
    transform: translateY(50px);
    &::after {
        position: absolute;
        left: -10px;
        bottom: -9px;
        content: '';
        width: 47px;
        height: 28px;
        background-image: url('/img/main/left_speech_bubble.png');
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(11deg);
    }
`;

const ChatBubbleLeft3 = styled.span`
    display: inline-block;
    padding: 23px 30px;
    background-color: var(--main-color);
    border-radius: 30px;
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    line-height: auto;
    margin-top: 51px;
    opacity: 0;
    transform: translateY(50px);
    &::after {
        position: absolute;
        left: -10px;
        bottom: -9px;
        content: '';
        width: 47px;
        height: 28px;
        background-image: url('/img/main/left_speech_bubble.png');
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(11deg);
    }
`;

const ChatBubbleRight1 = styled.span`
    display: inline-block;
    padding: 23px 30px;
    background-color: #b7faea;
    border-radius: 30px;
    color: var(--default-text);
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4;
    margin-top: 56px;
    opacity: 0;
    transform: translateY(50px);
`;

const ChatBubbleRight2 = styled.span`
    display: inline-block;
    padding: 23px 30px;
    background-color: #9ffce5;
    border-radius: 30px;
    color: var(--default-text);
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4;
    margin-top: 16px;
    opacity: 0;
    transform: translateY(50px);
    &::after {
        position: absolute;
        right: -10px;
        bottom: -10px;
        content: '';
        width: 43px;
        height: 35px;
        background-image: url('/img/main/right_speech_bubble1.png');
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(12deg);
    }
`;

const ChatBubbleRight3 = styled.div`
    position: absolute;
    bottom: 0;
    right: 60px;
    width: 619px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;
    background-color: #79ffde;
    border-radius: 30px;
    color: var(--default-text);
    overflow-x: hidden;
    /* margin-top: 56px; */
    /* &::after{
        position: absolute;
        right: -10px;
        bottom: -15px;
        content: '';
        width: 43px;
        height: 35px;
        background-image: url('/img/main/right_speech_bubble2.png');
        background-size: cover;
        background-repeat: no-repeat;
        transform: rotate(12deg);
    } */
`;

const ChatBubbleComment = styled.p`
    font-size: 24px;
    font-weight: 500;
    line-height: auto;
`;

const ChatSlideWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    transform: translateX(0);
`;

const ChatSlideBox = styled.div`
    min-width: 147px;
    height: 147px;
    /* border-radius: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    background-position: center;
    &:first-of-type {
        background-image: url('/img/main/category1.jpg');
    }
    &:nth-of-type(2) {
        background-image: url('/img/main/category2.jpg');
    }
    &:nth-of-type(3) {
        background-image: url('/img/main/category3.jpg');
    }
    &:nth-of-type(4) {
        background-image: url('/img/main/category4.jpg');
    }
    &:nth-of-type(5) {
        background-image: url('/img/main/category5.jpg');
    }
    &:nth-of-type(6) {
        background-image: url('/img/main/category6.jpg');
        margin-right: 0;
    }
`;

const ChatSlideTitle = styled.h3`
    font-size: 14px;
    font-weight: 600;
    line-height: auto;
    color: #ffffff;
    margin-bottom: 8px;
    white-space: nowrap;
`;

const ChatSlideText = styled.p`
    font-size: 9px;
    font-weight: 400;
    line-height: auto;
    color: #ffffff;
    white-space: nowrap;
`;

// const EmptyPad = styled.div`
//     position: relative;
//     top:0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     inset: 0;
//     width:100%;
//     height: 160px;
//     transform: translate(0);
//     background-color: transparent;
//     user-select: none;
// `;

export default MainFourthSection;
