// import React from 'react';
import styled from '@emotion/styled/macro';
import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useRef, useEffect, WheelEvent } from 'react';
import './carbon_footprint.css';

gsap.registerPlugin(ScrollTrigger);

function MainCalcSection() {
    const navigate = useNavigate();
    const Title1Ref = useRef(null);
    const Title2Ref = useRef(null);
    const Text1Ref = useRef(null);

    const ScrollStop = document.querySelector('.calculator-scroll');
    const preventScroll = (e: WheelEvent) => {
        e.preventDefault();     
        (ScrollStop as HTMLElement).style.overflowY = "hidden";
    };

    // type ValueMouseEvent = React.ChangeEvent<HTMLInputElement>;

    useEffect (() => {
        gsap.to(
            Title1Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                scrollTrigger: {
                    trigger:'.calc-title',
                    start: '30% bottom', 
                },
            },
        );

        gsap.to(
            Title2Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.3, 
                scrollTrigger: {
                    trigger:'.calc-title',
                    start: '30% bottom', 
                },
            }
        );

        gsap.to(
            Text1Ref.current, 
            0.7, 
            { 
                opacity:1,
                delay:0.65,
                scrollTrigger: {
                    trigger:'.calc-text',
                    start: '30% bottom', 
                },
            }
        );

        const dropdownTrigger = document.querySelector('#dropdown-trigger');
        const selectDropdown = document.querySelector('#select-dropdown');
        const triggerArrow = document.querySelector('.radio-wrapper-arrow');
        const dietList = document.querySelectorAll('.diet-list');
        const cautionText = document.querySelector('.caution-text');
        const ranges = document.querySelectorAll('.range-wrapper');
        const nextStep = document.querySelector('.next-step-btn');
        const calcCover = document.querySelector('.calculator-cover');
        const calcReset = document.querySelector('#calc-reset');
        const calcSubmit = document.querySelector('#calc-submit');
        const radioBox = document.querySelectorAll('.radio-container');
        let selectSlide = false;

        // 드롭다운 클릭
        const dropDownOpen = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            if (target.className === 'radio-trigger') {
                (selectDropdown as HTMLElement).classList.add('select-box-slide');
                (dropdownTrigger as HTMLElement).style.border = 'none';
                (triggerArrow as HTMLElement).classList.add('arrow-lotate');
                (cautionText as HTMLElement).style.opacity = "0";
                selectSlide = true; 
            } 
        };

        (dropdownTrigger as HTMLElement).addEventListener('click', dropDownOpen);

        // 드롭다운 리스트 클릭
        dietList.forEach((dietListButton) => {
            dietListButton.addEventListener('click', () => {
                (selectDropdown as HTMLElement).classList.remove('select-box-slide');
                (triggerArrow as HTMLElement).classList.remove('arrow-lotate');
                (dropdownTrigger as HTMLElement).style.color = "#191919";
                (dropdownTrigger as HTMLElement).innerText = `${dietListButton.textContent}`;
            });
        });

        // 드롭다운 이외의 영역 클릭
        const handleClickOutside = (e: MouseEvent) => {
            if (selectSlide === true) {
                const target = e.target as HTMLElement;
                if (target.id !== 'dropdown-trigger') {
                    (selectDropdown as HTMLElement).classList.remove('select-box-slide');
                    (triggerArrow as HTMLElement).classList.remove('arrow-lotate');
                    selectSlide = false;
                }
            }
        };
        document.addEventListener('click', handleClickOutside);

        // range 값 실시간 변경
        ranges.forEach((rangeInput) => {
            const range = rangeInput.querySelector<HTMLInputElement>(".input-range");
            const rangeV = rangeInput.querySelector<HTMLDivElement>(".range-value");
            const setValue = () => {
                if (range && rangeV) {
                    const newValue = (((Number(range.value) - Number(range.min)) * Number(100)) / (Number(range.max) -  Number(range.min)));
                    rangeV.innerHTML = `<span>${range.value}</span>`;
                    const newPosition = 12 - newValue * 0.24;
                    rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
                }
            };
            (range as HTMLInputElement).addEventListener("input", setValue);  
            document.addEventListener("DOMContentLoaded", setValue);
        });
        
        // 다음 계산 이동 할 때
        function nextStepCheck(){
            const isChecked = (value: string): boolean => {
                const inputChk = document.querySelector<HTMLInputElement>('input[name="diet"]:checked');
                return inputChk ? inputChk.value === value : false;
            };

            const isCheckedValueZero = isChecked('0');
            if(isCheckedValueZero === true){
                (dropdownTrigger as HTMLElement).style.border = 'solid #F34744';
                (cautionText as HTMLElement).style.opacity = "1";
                (dropdownTrigger as HTMLElement).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }else{
                scrollEnd();
            }
        }  

        (nextStep as HTMLElement).addEventListener('click', nextStepCheck);

        // 스크롤 하단 이동
        function scrollEnd(){
            (ScrollStop as HTMLElement).scrollTo({
                top:(ScrollStop as HTMLElement).scrollHeight,
                left:0,
                behavior:'smooth'
            });
        }

        // 계산 초기화
        function scrollReset(){
            (ScrollStop as HTMLElement).scrollTo({
                top:0,
                left:0,
                behavior:'smooth'
            });

            radioBox.forEach((dietButton) => {
                const radio = dietButton.querySelectorAll<HTMLInputElement>(".diet-input");
                const resetRadio = () => {
                    (radio[0] as HTMLInputElement).checked = true;
                    (radio[0] as HTMLInputElement).defaultChecked = true;
                };
                resetRadio();
            });

            // radioBox.forEach((dietButton) => {
            //     const radio = dietButton.querySelector<HTMLInputElement>(".diet-input");
            //     const resetRadio = () => {
            //         (radio as HTMLInputElement).defaultChecked = false;
            //     };
            //     resetRadio();
            // });

            ranges.forEach((rangeInput) => {
                const range = rangeInput.querySelector<HTMLInputElement>(".input-range");
                const rangeV = rangeInput.querySelector<HTMLDivElement>(".range-value");
                const resetValue = () => {
                    if (range && rangeV) {
                        range.value = '0';
                        rangeV.innerHTML =  `<span>0</span>`;
                        rangeV.style.left = `calc(0% + (12px))`;
                    }
                };
                resetValue();
            });

            (triggerArrow as HTMLElement).classList.remove('arrow-lotate');
            (dropdownTrigger as HTMLElement).style.color = "#b1b1b1";
            (dropdownTrigger as HTMLElement).innerText = '이렇게';
            (calcCover as HTMLElement).classList.remove('cover-loading');
        }

        (calcReset as HTMLElement).addEventListener('click', scrollReset);

        // 계산 완료
        function CalcComplete(){
            const amountV = document.querySelector('.result-amount');
            const checked1 = document.querySelector<HTMLInputElement>('input[name="diet"]:checked');
            const resultV = document.querySelector('.result-volume');
            const treeV = document.querySelector('.result-tree');
            const range = document.querySelectorAll<HTMLInputElement>(".input-range");
            const rangeResult = 
            (Number((range[0] as HTMLInputElement).value) * 10000 * 0.00033826 * 52 +
            Number((range[1] as HTMLInputElement).value) * 10000 * 0.00047410 * 12 +
            Number((range[2] as HTMLInputElement).value) * 10000 * 0.00059958 * 12 +
            Number((range[3] as HTMLInputElement).value) * 10000 * 0.00112429 * 12 +
            Number((range[4] as HTMLInputElement).value) * 10000 * 0.00012573 * 12 +
            Number((range[5] as HTMLInputElement).value) * 10000 * 0.00037405 * 12 +
            Number((range[6] as HTMLInputElement).value) * 10000 * 0.00057110 * 12 +
            Number((range[7] as HTMLInputElement).value) * 10000 * 0.00114798 * 12 +
            Number((range[8] as HTMLInputElement).value) * 10000 * 0.00080889 * 12 +
            Number((range[9] as HTMLInputElement).value) * 10000 * 0.00169212);
            console.log(rangeResult);
            const totalResult = Number((checked1 as HTMLInputElement).value) + rangeResult;
            if(amountV instanceof HTMLElement){
                (amountV as HTMLElement).innerText = String(parseFloat((totalResult * Number(0.001)).toFixed(2)));
                if(Number(amountV.innerText) <= 9){
                    (resultV as HTMLElement).innerText = '적게';
                }else{
                    (resultV as HTMLElement).innerText = '많이';
                }
                (treeV as HTMLElement).innerText = String(Math.ceil(Number((totalResult / 6600))));             
            }
            if (calcCover instanceof HTMLElement) {
                (calcCover as HTMLElement).classList.add('cover-loading');
            }
        }

        (calcSubmit as HTMLElement).addEventListener('click', CalcComplete);
    });

    return (
        
        <Container>
            <Section>
                <TitleWrapper className="calc-title">
                    <TitleBox >
                        <Title ref={Title1Ref}>
                            나는 지금 지구를 얼마나
                        </Title>
                    </TitleBox>
                    <TitleBox>
                        <Title ref={Title2Ref}>더럽히고 있을까요?</Title>
                    </TitleBox>
                </TitleWrapper>
                <Description className='calc-text' ref={Text1Ref}>1년 동안 내가 배출한 탄소량을 계산해보세요.</Description>
                <div className="calculator-wrppaer">
                    <div className="calculator-left">
                        <div className="calculator-scroll" onWheel={preventScroll}>
                            <div className="calculator-one">
                                <div className="input-wrapper input-flex">
                                    <div className="category-icon">
                                        <img src="/img/main/icon_1.png" alt="icon1" />
                                    </div>
                                    <p>나는 식사를 할때</p>
                                    <div className="select-wrapper">
                                        <div className="radio-wrapper">
                                            <div id="dropdown-trigger" className='radio-trigger'>
                                                이렇게
                                            </div>
                                            <div className='radio-container'>
                                                <div className='radio-box'>
                                                    <input type="radio" name="diet" className="diet-input diet-input-default" id="diet-none" title="이렇게" defaultValue="0" defaultChecked/> 
                                                </div>
                                                <div className='radio-box'>
                                                    <input type="radio" name="diet" className="diet-input" id="diet-1" title="모든 음식을 골고루" defaultValue="3300" /></div>   
                                                <div className='radio-box'>
                                                    <input type="radio" name="diet" className="diet-input" id="diet-2" title="붉은 고기는 빼고" defaultValue="2500" />
                                                </div>
                                                <div className='radio-box'>
                                                    <input type="radio" name="diet" className="diet-input" id="diet-3" title="육류 및 해산물은 빼고" defaultValue="1900" />
                                                </div>
                                                <div className='radio-box'>
                                                    <input type="radio" name="diet" className="diet-input" id="diet-4" title="채식주의 식단으로" defaultValue="1700" />
                                                </div>
                                            </div> 
                                            <span className="caution-text">* 필수 선택사항입니다.</span>
                                            <span className="radio-wrapper-arrow" />
                                        </div>
                                        <ul className="select-box" id="select-dropdown">
                                            <li>
                                                <label className="diet-list" htmlFor="diet-1" >
                                                    모든 음식을 골고루
                                                </label>
                                            </li>
                                            <li>
                                                <label className="diet-list" htmlFor="diet-2" >
                                                    붉은 고기는 빼고
                                                </label>
                                            </li>
                                            <li>
                                                <label className="diet-list" htmlFor="diet-3">
                                                    육류 및 해산물은 빼고
                                                </label>
                                            </li>
                                            <li>
                                                <label className="diet-list" htmlFor="diet-4">
                                                    채식주의 식단으로
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <p>먹어요.</p>
                                </div>
                                <div className="input-wrapper">
                                    <div className="input-flex">  
                                        <div className="category-icon">
                                            <img src="/img/main/icon_2.png" alt="icon2" />
                                        </div>
                                        <p>나는 테이크 아웃 음료 구매를 위해 1주일에</p>
                                    </div>
                                    <div className="input-flex">  
                                        <div className="category-icon" />
                                        <div className="range-wrapper">
                                            <div className="range-value" id="rangeValue1">
                                                <span>0</span>
                                            </div>
                                            <input id="range1" className="input-range" type="range" min="0" max="20" step="1" defaultValue="0"  />
                                        </div>
                                        <p>만원 사용해요.</p>
                                    </div>
                                </div>
                                <div className="input-wrapper">
                                    <div className="input-flex">  
                                        <div className="category-icon">
                                            <img src="/img/main/icon_clothes.png" alt="clothes" />
                                        </div>
                                        <p>나는 옷을 구매하기 위해 한 달에</p>
                                    </div>
                                    <div className="input-flex">  
                                        <div className="category-icon" />
                                        <div className="range-wrapper">
                                            <div className="range-value" id="rangeValue2">
                                                <span>0</span>
                                            </div>
                                            <input id="range2" className="input-range" type="range" min="0" max="20" step="1" defaultValue="0"  />
                                        </div>
                                        <p>만원 사용해요.</p>
                                    </div>
                                </div>
                                <div className="input-wrapper">
                                    <div className="input-flex">  
                                        <div className="category-icon">
                                            <img src="/img/main/icon_shoes.png" alt="shoes" />
                                        </div>
                                        <p>나는 신발을 구매하기 위해 한 달에</p>
                                    </div>
                                    <div className="input-flex">  
                                        <div className="category-icon" />
                                        <div className="range-wrapper">
                                            <div className="range-value" id="rangeValue">
                                                <span>0</span>
                                            </div>
                                            <input id="range3" className="input-range" type="range" min="0" max="20" step="1" defaultValue="0" />
                                        </div>
                                        <p>만원 사용해요.</p>
                                    </div>
                                </div>
                                <div className="input-wrapper last-input-wrapper">
                                    <div className="input-flex">  
                                        <div className="category-icon">
                                            <img src="/img/main/icon_5.png" alt="icon5" />
                                        </div>
                                        <p>나는 세탁 및 욕실 용품 구매를 위해 한 달에</p>
                                    </div>
                                    <div className="input-flex">  
                                        <div className="category-icon" />
                                        <div className="range-wrapper">
                                            <div className="range-value" id="rangeValue4">
                                                <span>0</span>
                                            </div>
                                            <input id="range4" className="input-range" type="range" min="0" max="20" step="1" defaultValue="0" />
                                        </div>
                                        <p>만원 사용해요.</p>
                                    </div>
                                </div>
                                <div className="input-wrapper last-input-wrapper">
                                    <div className="input-flex">  
                                        <div className="category-icon">
                                            <img src="/img/main/icon_3.png" alt="OTT" />
                                        </div>
                                        <p>나는 OTT 이용을 위해 한 달에</p>
                                    </div>
                                    <div className="input-flex">  
                                        <div className="category-icon" />
                                        <div className="range-wrapper">
                                            <div className="range-value" id="rangeValue5">
                                                <span>0</span>
                                            </div>
                                            <input id="range5" className="input-range" type="range" min="0" max="20" step="1" defaultValue="0" />
                                        </div>
                                        <p>만원 사용해요.</p>
                                    </div>
                                </div>
                                <div className="next-step">
                                    <div className="next-step-btn">
                                        <p>다음 페이지</p>
                                        <span />
                                    </div>
                                </div>
                            </div>
                            <div className="calculator-two">
                                <div className="input-wrapper">
                                    <div className="input-flex">  
                                        <div className="category-icon">
                                            <img src="/img/main/icon_buliding.png" alt="buliding" />
                                        </div>
                                        <p>나는 여행(또는 출장) 숙박 비용으로 한 달에</p>
                                    </div>
                                    <div className="input-flex">  
                                        <div className="category-icon" />
                                        <div className="range-wrapper">
                                            <div className="range-value" id="rangeValue6">
                                                <span>0</span>
                                            </div>
                                            <input id="range6" className="input-range" type="range" min="0" max="20" step="1" defaultValue="0" />
                                        </div>
                                        <p>만원 사용해요.</p>
                                    </div>
                                </div>
                                <div className="input-wrapper">
                                    <div className="input-flex">  
                                        <div className="category-icon">
                                            <img src="/img/main/icon_7.png" alt="icon7" />
                                        </div>
                                        <p>나는 자동차 운행을 위해 한 달에</p>
                                    </div>
                                    <div className="input-flex">  
                                        <div className="category-icon" />
                                        <div className="range-wrapper">
                                            <div className="range-value" id="rangeValue7">
                                                <span>0</span>
                                            </div>
                                            <input id="range7" className="input-range" type="range" min="0" max="100" step="5" defaultValue="0" />
                                        </div>
                                        <p>만원 사용해요.</p>
                                    </div>
                                </div>
                                <div className="input-wrapper">
                                    <div className="input-flex">  
                                        <div className="category-icon">
                                            <img src="/img/main/icon_8.png" alt="icon8" /> 
                                        </div>
                                        <p>나는 버스를 탑승을 위해 한 달에</p>
                                    </div>
                                    <div className="input-flex">  
                                        <div className="category-icon" />
                                        <div className="range-wrapper">
                                            <div className="range-value" id="rangeValue8" >
                                                <span>0</span>
                                            </div>
                                            <input id="range8" className="input-range" type="range" min="0" max="50" step="1" defaultValue="0" />
                                        </div>
                                        <p>만원 사용해요.</p>
                                    </div>
                                </div>
                                <div className="input-wrapper">
                                    <div className="input-flex">  
                                        <div className="category-icon">
                                            <img src="/img/main/icon_9.png" alt="icon9" />
                                        </div>
                                        <p>나는 철도 탑승을 위해 한 달에</p>
                                    </div>
                                    <div className="input-flex">  
                                        <div className="category-icon" />
                                        <div className="range-wrapper">
                                            <div className="range-value" id="rangeValue9">
                                                <span>0</span>
                                            </div>
                                            <input id="range9" className="input-range" type="range" min="0" max="50" step="1" defaultValue="0" />
                                        </div>
                                        <p>만원 사용해요.</p>
                                    </div>
                                </div>
                                <div className="input-wrapper last-input-wrapper">
                                    <div className="input-flex">  
                                        <div className="category-icon">
                                            <img src="/img/main/icon_10.png" alt="icon10" />
                                        </div>
                                        <p>나는 비행기로 1년에</p>
                                    </div>
                                    <div className="input-flex">  
                                        <div className="category-icon" />
                                        <div className="range-wrapper">
                                            <div className="range-value" id="rangeValue10" >
                                                <span>0</span>
                                            </div>
                                            <input id="range10" className="input-range" type="range" min="0" max="300" step="1" defaultValue="0" />
                                        </div>
                                        <p>만원 사용해요.</p>
                                    </div>
                                </div>
                                <div className="calculator-button-wrapper">
                                    <button type="button" className="calculator-button calculator-reset" id="calc-reset">다시 계산하기</button>
                                    <button type="button" className="calculator-button calculator-submit" id="calc-submit">나의 탄소량 결과보기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="calculator-right">
                        <div className="calculator-cover">
                            <strong>탄소발자국 계산기</strong>
                            <p>탄소발자국이란<br /> 
                                우리의 일상생활에서 발생하는<br />
                                이산화탄소의 수치를 뜻해요.
                            </p>
                            <p>탄소발자국에 대한 이해는<br /> 
                                환경보호를 위한 첫걸음이에요.
                            </p>
                            <div className="cover-image">
                                <img src="/img/main/illust_foot.png" alt="footprint" />
                            </div>
                        </div>
                        <div className="calculator-result">
                            <p className="result-value">
                                나의 1년간 탄소배출량은<br />
                                <span className="result-amount" id="result-amount">1.5</span>톤입니다.
                            </p>
                            <div className="result-image">
                                <img src="/img/main/illust_forest.png" alt="" />
                            </div>
                            <div className="result-text-wrapper">
                                <p className="result-text">
                                    1인 평균 탄소배출량에 비해 <span className="result-volume">적게</span> 배출하고 있어요.
                                </p>
                                <p className="result-text">내가 1년간 배출한 탄소를 없애기 위해서는<br />
                                    약 <span className="result-tree">00</span>그루의 나무를 심어야 해요.
                                </p>
                            </div>
                            <p className="result-tip">＊ 나무 한 그루가 1년동안 흡수하는 평균 이산화탄소량은 6.6kg이에요.</p>
                            <MoreButton onClick={() => navigate('/marketplace')} className="market-link-button">탄소배출권 구매하기</MoreButton>
                        </div>
                    </div>
                </div>
            </Section>
        </Container>
    );
}

const Container = styled.div`
    width: 1280px;
    margin: 0 auto;
`;

const Section = styled.div`
    width: 100%;
    position: relative;
    padding-bottom: 160px;
`;

const TitleWrapper = styled.div`
    display: block;
`;

const TitleBox = styled.div`
    display: block;
    overflow: hidden;
`;

const Title = styled.h2`
    color:var(--default-text);
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.4;
    opacity: 0;
    transform: translateY(60px);
    & > span{
        color: var(--main-color);
    }
`;

const Description = styled.p`
    margin-top: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;
    color: var(--gray-scale1);
    opacity: 0;
`;

const MoreButton = styled.button`
   display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 64px;
    border-radius: 500px;
    margin-top: auto;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    background-color: var(--main-color);
    transition-duration: .3s;
    &:hover{
        background-color:#314AC1;
    }
`;


export default MainCalcSection;
