import styled from '@emotion/styled/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef, useEffect } from 'react';
import UNFCCC from '../../../public/img/main/UNFCCC_logo.png';
import VERRA from '../../../public/img/main/VERRA_logo.png';
import GS from '../../../public/img/main/GOLD_STANDARD_logo.png';

gsap.registerPlugin(ScrollTrigger);

function MainSeventhSection() {
    const Title1Ref = useRef(null);
    const Title2Ref = useRef(null);
    const Title3Ref = useRef(null);
    const Offset1Ref = useRef(null);
    const Offset2Ref = useRef(null);
    const Offset3Ref = useRef(null);
    useEffect(() => {
        gsap.to(
            Title1Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                scrollTrigger: {
                    trigger:'.seventh-title',
                    start: '30% bottom', 
                },
            },
        );

        gsap.to(
            Title2Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.3, 
                scrollTrigger: {
                    trigger:'.seventh-title',
                    start: '30% bottom', 
                },
            }
        );

        gsap.to(
            Title3Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.6, 
                scrollTrigger: {
                    trigger:'.seventh-title',
                    start: '30% bottom', 
                },
            }
        );

        gsap.to(
            Offset1Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                scrollTrigger: {
                    trigger:'.seventh-title',
                    start: '70% bottom', 
                },
            }
        );

        gsap.to(
            Offset2Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.2, 
                scrollTrigger: {
                    trigger:'.seventh-title',
                    start: '70% bottom', 
                },
            }
        );

        gsap.to(
            Offset3Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.4, 
                scrollTrigger: {
                    trigger:'.seventh-title',
                    start: '70% bottom', 
                },
            }
        );
    });
    return (
        <Container>
            <Section>
                <TitleWrapper className='seventh-title'>
                    <TitleBox >
                        <Title ref={Title1Ref}>
                            탄소배출권의
                        </Title>
                    </TitleBox>
                    <TitleBox>
                        <Title ref={Title2Ref}>개발부터 상쇄, 인증까지</Title>
                    </TitleBox>
                    <TitleBox>
                        <Title ref={Title3Ref} ><span>글로벌 인증기관</span>이 함께해요</Title>
                    </TitleBox>
                </TitleWrapper>
                <OffsetWrapper className='offset-wrapper'>
                    <OffsetLine>
                        <OffsetBox ref={Offset2Ref} className='offset-box2'>
                            <OffsetImg src={VERRA} alt="VERRA"/>
                            <OffsetBorder />
                            <OffsetTextWrapper>
                                <OffsetTitle>VERRA</OffsetTitle>
                                <OffsetText>지속가능한 발전과 환경보호를 위해<br />다양한 프로그램을 개발하고 관리</OffsetText>
                            </OffsetTextWrapper>
                        </OffsetBox>
                    </OffsetLine>
                    <OffsetLine>
                        <OffsetBox ref={Offset1Ref} className='offset-box1'>
                            <OffsetImg src={UNFCCC} alt="UNFCCC"/>
                            <OffsetBorder />
                            <OffsetTextWrapper>
                                <OffsetTitle>UNFCCC</OffsetTitle>
                                <OffsetText>기후변화 문제에 대응하기 위해<br />국제적으로 협력하는 유엔 산하의 조약</OffsetText>
                            </OffsetTextWrapper>
                        </OffsetBox>
                        <OffsetBox ref={Offset3Ref} className='offset-box3'>
                            <OffsetImg src={GS} alt="GOLD STANDARD"/>
                            <OffsetBorder />
                            <OffsetTextWrapper>
                                <OffsetTitle>Gold Standard</OffsetTitle>
                                <OffsetText>기후변화와 지속가능한 발전 목표 달성을 위한<br />환경 프로젝트를 인정하는 기관</OffsetText>
                            </OffsetTextWrapper>
                        </OffsetBox>
                    </OffsetLine>
                </OffsetWrapper>
                <SectionIcon />
            </Section>
        </Container>
    );
}

const Container = styled.div`
    width: 1280px;
    margin: 0 auto;

`;

const Section = styled.div`
    width: 100%;
    border-radius: 30px;
    overflow: hidden;
    background-color: #0e1646;
    padding:60px;
    position: relative;
`;

const SectionIcon = styled.div`
    position: absolute;
    bottom: 60px;
    left: 60px;
    background-image: url('/img/main/illust_tree.png');
    background-size: cover;
    width: 182px;
    height: 221px;
`;

const TitleWrapper = styled.div`
    display: block;
`;

const TitleBox = styled.div`
    display: block;
    overflow: hidden;
`;

const Title = styled.h2`
    color:#ffffff;
    text-align: left;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.4;
    opacity: 0;
    transform: translateY(60px);
    & > span{
        color: var(--sub-color);
    }
`;

const OffsetWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: -48px;
`;

const OffsetLine = styled.div`
    display: flex;
    flex-direction: column;
    &:first-of-type{
        justify-content: center;
    }
    &:last-of-type{
        justify-content: space-between;
        margin-left: 40px;
    }
    &:last-of-type > div:first-of-type{
        margin-bottom: 40px;
    }
`;

const OffsetBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 358px;
    height: 252px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 20px;
    opacity: 0;
    transform: translateY(100px);
`;  

const OffsetImg = styled.img`
    max-width: 100%;
    width: auto;
`;

const OffsetBorder = styled.div`
    width:100%;
    height: 1px;
    background-color: var(--main-color);
    opacity: 0.2;
`;

const OffsetTextWrapper = styled.div`
    
`;

const OffsetTitle = styled.h4`
    font-size: 22px;
    font-weight: 700;
    color: #161e46;
    margin-bottom: 6.7px;
`;

const OffsetText = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: var(--gray-scale1);
`;



export default MainSeventhSection;
