// import React from 'react';
import styled from '@emotion/styled/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef, useEffect, useState} from 'react';
import './rolling.css';
import { keyframes } from '@emotion/react';

gsap.registerPlugin(ScrollTrigger);

function MainEighthSection() {
   
    const slides = [
        { url: "url('/img/main/tantan_logo.png')"},
        { url: "url('/img/main/econine_logo.png')"},
        { url: "url('/img/main/tansobank_logo.png')"},
        { url: "url('/img/main/sl_logo.png')"},
    ];

    const Title1Ref = useRef(null);
    const Title2Ref = useRef(null);
    const [animate, setAnimate] = useState(true);
    const onStop = () => setAnimate(false);
    const onRun = () => setAnimate(true);
    
    useEffect(() => {
        gsap.to(
            Title1Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                scrollTrigger: {
                    trigger:'.eighth-title',
                    start: 'bottom bottom', 
                },
            },
        );

        gsap.to(
            Title2Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.5, 
                scrollTrigger: {
                    trigger:'.eighth-title',
                    start: 'bottom bottom', 
                },
            }
        );
        
    });
    return (
        <Container>
            <Section>
                <TitleWrapper className='eighth-title'>
                    <TitleBox>
                        <Title ref={Title1Ref}><span>탄소중립</span>을 실천하여</Title>
                    </TitleBox>
                    <TitleBox >
                        <Title ref={Title2Ref} >
                            기후 위기로부터 지구를 구하고 있어요
                        </Title>
                    </TitleBox>
                </TitleWrapper>
                <div className="wrapper">
                    <SlideContainer>
                        <SliderShadow1 />
                        <SlideWrapper
                            onMouseEnter={onStop}
                            onMouseLeave={onRun}
                        >   
                            
                            <Slide
                                className={"original".concat(
                                    animate ? "" : ""
                                )}
                            >
                                {slides.map((s, i) => (
                                    <SlideList
                                        key={i}>
                                        <LogoImg style={{ backgroundImage: s.url }} />
                                    </SlideList>
                                ))}
                            </Slide>
                            <Slide
                                className={"clone".concat(animate ? "" : "")}
                            >
                                {slides.map((s, i) => (
                                    <SlideList
                                        key={i}>
                                        <LogoImg style={{ backgroundImage: s.url }} />
                                    </SlideList>
                                ))}
                            </Slide>
                        </SlideWrapper>
                        <SliderShadow2 />
                    </SlideContainer>
                </div>
                <SectionIcon />
            </Section>
        </Container>
    );
}

const Container = styled.div`
    width: 1280px;
    margin: 0 auto;
    margin-top: 40px;
    padding-bottom: 170px;
`;

const Section = styled.div`
    width: 100%;
    border-radius: 30px;
    overflow: hidden;
    background-color: #0e1646;
    padding:60px;
    position: relative;
`;

const SectionIcon = styled.div`
    position: absolute;
    top: 50px;
    right: 38px;
    background-image: url('/img/main/illust_earth.png');
    background-size: cover;
    width: 246px;
    height: 185px;
`;

const TitleWrapper = styled.div`
    display: block;
`;

const TitleBox = styled.div`
    display: block;
    overflow: hidden;
`;

const Title = styled.h2`
    color:#ffffff;
    text-align: left;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.4;
    opacity: 0;
    transform: translateY(60px);
    & > span{
        color: var(--sub-color);
    }
`;

const SlideContainer = styled.div`
    position: relative;
    overflow: hidden;
    max-width: 1142px;
    margin: 0 auto;
    margin-top: 102px;
`;

const SlideWrapper = styled.ul`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    
`;

const SliderShadow1 = styled.div`
    position: absolute;
    left:0;
    top:0;
    width: 286px;
    height: 100%;
    background: linear-gradient(270deg, rgba(33, 44, 101, 0) 0%, rgba(14, 22, 70, 0.7) 50%, rgb(14, 22, 70) 100%);
    z-index: 2;
`;

const SliderShadow2 = styled.div`
    position: absolute;
    right:0;
    top:0;
    width: 286px;
    height: 100%;
    background: linear-gradient(90deg, rgba(33, 44, 101, 0) 0%, rgba(14, 22, 70, 0.7) 50%, rgb(14, 22, 70) 100%);
    z-index: 2;
`;

const infiniteAnimation1 = keyframes`
    0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(-100%);
    }
    50.1% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
`;

const infiniteAnimation2 = keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-200%);
    }
`;

const Slide = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;

    padding: 40px 0;
    &.original {
        animation: 50s linear infinite normal none running ${infiniteAnimation1};
    }
    &.clone {
        animation: 50s linear infinite ${infiniteAnimation2};
    }
    &.stop {
        animation-play-state: paused;
    }
`;  

const SlideList = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    z-index: 2;
    transition: 0.3s;
    transform: scale(1);
    min-width:252px;
    width: 100%;
    margin-right: 15px;
    border:2px solid rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    height: 136px;
    
`;

const LogoImg = styled.div`
    background-size: auto 30px;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
`;

export default MainEighthSection;
