import React from 'react';
import styled from '@emotion/styled/macro';
// import moment from 'moment';
import { colors } from '@styles/ui_palette';

// Components
import FooterGnb from '@components/layout/footer/FooterGnb';
// import FooterInformation from '@components/layout/footer/FooterInformation';

function Footer() {
    return (
        <>
            <CompanyInfoSection>
                <CompanyInfoContainer>
                    <CompanyInfoBox>
                        <CompanyInfo>
                            <InfoLine>
                                <Company type="text">회사명&nbsp;:&nbsp;(주)e-ETS</Company>
                                <Company type="bar">|</Company>
                                <Company type="text">대표 : 권한주</Company>
                            </InfoLine>
                            <InfoLine>
                                <Company type="text">
                                    통신판매업신고번호 제 2023-서울성동-1285 호
                                </Company>
                            </InfoLine>
                            <InfoLine>
                                <Company type="text">
                                    사업자등록번호 : 331-87-03009
                                </Company>
                            </InfoLine>
                            <InfoLine>
                                <Company type="text">
                                    개인정보보호책임자 권한주
                                </Company>
                            </InfoLine>
                        </CompanyInfo>
                    </CompanyInfoBox>
                    <CustomerBox>
                        <CustomerCenter>
                            <CustomerCenterTitle>
                                문의전화 
                                <CunstomerTime>
                                    (평일 09:00 - 18:00, 주말 및 공휴일 휴무)
                                </CunstomerTime>
                            </CustomerCenterTitle>
                            <CustomerNumber>
                                02. 6217. 3000
                            </CustomerNumber>
                        </CustomerCenter>
                    </CustomerBox>
                </CompanyInfoContainer>
            </CompanyInfoSection>
            <FooterGnb />
            {/* <FooterInformation /> */}
        </>
    );
}

const CompanyInfoSection = styled.div`
    padding:36px 0;
    background-color: #f9f9f9;
`;

const CompanyInfoContainer = styled.div`
    width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;

const CompanyInfoBox = styled.div`
    flex: calc(100% - 355px);
`;

const CompanyInfo = styled.ul`
    
`;

const InfoLine = styled.li`
    display: flex;
    align-items: center;
    flex: 1;
`;

const Company = styled.div<{ type: string; active?: boolean }>`
    margin: ${(props: { type: string }) => (props.type === 'bar' ? '0 14px' : 0)};
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 300;
    line-height: auto;
    /* color: ${(props) => (props.active ? colors.Black100 : colors.BlueGray700)}; */
`;

const CustomerBox = styled.div``;

const CustomerCenter = styled.div`
    margin-top: 20px;
`;

const CustomerCenterTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 500;

`;

const CunstomerTime = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,.5);
    margin-left: 6px;
`;

const CustomerNumber = styled.div`
    font-size: 40px;
    font-weight: 500;
    color: var(--main-color);
    border-bottom: 2px solid var(--main-color);
    margin-top: 3px;
`;

export default Footer;
