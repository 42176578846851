import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@styles/ui_palette';
import { rgba } from 'emotion-rgba';
import { useForm } from 'react-hook-form';
import { Store } from 'react-notifications-component';
import { Axios } from '@utils/api';
import { UserAddress } from '@recoil/auth';
import { useRecoilValue } from 'recoil';

import BurnForm from '@components/myPage/BurnForm';

interface IBurnModal {
    title: string;
    asset_id: string;
    asset_quantity: number;
    more_info_url: string;
    handleModal: any;
}

type BurnData = {
    cer_name: string;
    offset_qty: string;
    offset_use: string;
    offset_name: string;
    offset_juno: string;
};

function BurnModal({ handleModal, title, asset_id, asset_quantity, more_info_url }: IBurnModal) {
    const userAddress = useRecoilValue(UserAddress);
    const [loading, setLoading] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { isValid, errors },
    } = useForm<BurnData>({
        mode: 'onChange',
        defaultValues: {
            offset_qty: '1',
            offset_use: '기후변화 대응에 동참',
        },
    });

    const notiOption = {
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 2000,
        },
    };

    const onSubmit = useCallback(async (data: BurnData) => {
        setLoading(true);
        const token = `Bearer ${localStorage.getItem('token')}` || '';

        if (!localStorage.getItem('token')) {
            Store.addNotification({
                ...notiOption,
                title: '오류',
                message: '로그인해 주세요.',
                type: 'danger',
                container: 'top-left',
                insert: 'top',
            });
            setLoading(false);
            return;
        }

        if (Number(asset_quantity) < Number(data.offset_qty)) {
            Store.addNotification({
                ...notiOption,
                title: '오류',
                message: '보유한 수량보다 많습니다.',
                type: 'danger',
                container: 'top-left',
                insert: 'top',
            });
            setLoading(false);
            return;
        }

        if (Number(data.offset_qty) <= 0) {
            Store.addNotification({
                ...notiOption,
                title: '오류',
                message: '상쇄수량을 제대로 입렵해주세요.',
                type: 'danger',
                container: 'top-left',
                insert: 'top',
            });
            setLoading(false);
            return;
        }

        const formData = new FormData();

        formData.append('wallet_address', userAddress.address);
        formData.append('blockchain', 'klaytn');
        formData.append('asset_id', asset_id);
        formData.append('cer_name', title);
        formData.append('offset_qty', data.offset_qty);
        formData.append('offset_use', data.offset_use);
        formData.append('offset_name', data.offset_name);
        formData.append('offset_juno', data.offset_juno);
        formData.append('more_info_url', more_info_url);

        try {
            const { data } = await Axios('add_offset_request', formData, token);

            if (data) {
                Store.addNotification({
                    ...notiOption,
                    title: '완료',
                    message: '상쇄신청이 완료되었습니다.',
                    type: 'default',
                    container: 'top-left',
                    insert: 'top',
                });
                handleModal();
            }
        } catch (error) {
            Store.addNotification({
                ...notiOption,
                title: '오류',
                message: '오류입니다 다시 시도해주세요.',
                type: 'danger',
                container: 'top-left',
                insert: 'top',
            });
            handleModal();
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <>
            <Container onClick={handleModal} />
            <Modals>
                <ModalHead>
                    상쇄하기
                    <CloseButton onClick={handleModal} />
                </ModalHead>
                <ModalBody>
                    <ProfileEditContainer onSubmit={handleSubmit(onSubmit)}>
                        <ContentsWrap>
                            <BurnTitleBox>
                                <TitleType>배출권 명</TitleType>
                                <BurnTitle>{title}</BurnTitle>
                            </BurnTitleBox>

                            {/* 폼 양식 */}
                            <BurnForm
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                getValues={getValues}
                                watch={watch}
                            />

                            <Notice>위 입력하신 정보와 같이 상쇄를 진행하겠습니다.</Notice>

                            <ButtonGroup>
                                <Submit
                                    type="submit"
                                    value="상쇄하기"
                                    disabled={!isValid || loading}
                                />
                            </ButtonGroup>
                        </ContentsWrap>
                    </ProfileEditContainer>
                </ModalBody>
            </Modals>
        </>
    );
}

const Container = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1001;
    background-color: ${rgba(colors.Black200, 0.8)};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Modals = styled.div`
    width: 500px;
    background-color: ${colors.White100};
    border-radius: 12px;
    min-height: 512px;
    padding: 30px;
    padding-top: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10002;
`;

const ModalHead = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: ${colors.Black100};
    padding: 16px 0;
    border-bottom: 1px solid ${colors.WarmGray200};
    text-align: center;
    position: relative;
`;

const ModalBody = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`;

const CloseButton = styled.div`
    width: 32px;
    height: 32px;
    background-image: url('/img/main/modal/ic_modal_close.svg');
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        transition: all 0.2s ease;
        background-image: url('/img/main/modal/ic_modal_close_black.svg');
    }
`;

const ProfileEditContainer = styled.form``;

const ContentsWrap = styled.div``;

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
`;

const Submit = styled.input`
    flex: 1;
    height: 58px;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    background-color: ${colors.Black200};
    color: ${colors.White100};
    cursor: pointer;

    &:disabled {
        background-color: ${colors.BlueGray300};
        color: ${colors.BlueGray500};
        cursor: not-allowed;
    }
`;

const BurnTitle = styled.div`
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
`;

const BurnTitleBox = styled.div`
    margin-bottom: 8px;
`;

const TitleType = styled.div`
    font-size: 14px;
    color: ${colors.Black100};
    font-weight: 500;
    margin-bottom: 10px;
`;

const Notice = styled.div`
    text-align: center;
    margin-bottom: 30px;
    font-weight: 700;
`;

export default BurnModal;
