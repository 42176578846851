import styled from '@emotion/styled/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef, useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

function MainSecondSection() {  
    const Title1Ref = useRef(null);
    const Title2Ref = useRef(null);
    useEffect(() => {
        gsap.to(
            Title1Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                scrollTrigger: {
                    trigger:'.second-title',
                    start: '30% bottom', 
                },
            },
        );

        gsap.to(
            Title2Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.3, 
                scrollTrigger: {
                    trigger:'.second-title',
                    start: '30% bottom', 
                },
            });
    }, []);
    return (
        <Container>
            <Section>
                <LeftContent>
                    <StickyWrapper>
                        <TitleWrapper className="second-title">
                            <TitleBox>
                                <Title ref={Title1Ref} >기후 변화를 겪고 있는 지구,</Title>
                            </TitleBox>
                            <TitleBox>
                                <Title ref={Title2Ref}>우리의 행동도 변화가 필요해요</Title>
                            </TitleBox>
                        </TitleWrapper>
                        <StickyImg />
                    </StickyWrapper>
                </LeftContent>
                <RightContent>
                    <ContentBox>
                        <ContentImg />
                        <ContentText>
                            우리는 보다 더 편안한 삶을 위해, <br/> 끊임없이 발전하고 있어요.
                        </ContentText>
                    </ContentBox>
                    <ContentBox>
                        <ContentImg />
                        <ContentText>
                            그 과정에서 지구는 병들고, <br/> 다양한 환경적인 변화가 일어나죠.
                        </ContentText>
                    </ContentBox>
                    <ContentBox>
                        <ContentImg /> 
                        <ContentText>
                            기후와 생태계는 파괴되어, <br/> 결국 우리에게도 나쁜 결과를 초래해요.
                        </ContentText>
                    </ContentBox>
                </RightContent>
            </Section>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    margin-top: 100px;
    background-color: #f9f9f9;
`;

const Section = styled.div`
    padding-top: 178px;
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 160px;
`;

const LeftContent = styled.div`
    position: relative;
    display: block;
`;

const StickyWrapper = styled.div`
    position: sticky;
    top:221px;
`;

const TitleWrapper = styled.div`
    display: block;
`;

const TitleBox = styled.div`
    display: block;
    overflow: hidden;
`;

const Title = styled.h2`
    color:var(--default-text);
    text-align: left;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.4;
    opacity: 0;
    transform: translateY(60px);
`;

const StickyImg = styled.div`    
    width: 360px;
    height: 508.5px;
    background-image: url('/img/main/trash_illust.png');
    background-size: cover;
    margin-top: 270px;
`;  

const RightContent = styled.div`
    padding-bottom: 250px;
    margin-top: 200px;
`;

const ContentBox = styled.div`
    margin-bottom: 100px;
    &:last-of-type{
        margin-bottom: 0;
    }
    &:first-of-type > div{
        background-image: url('/img/main/scroll_img1.jpg');
    }
    &:nth-of-type(2) > div{
        background-image: url('/img/main/scroll_img2.jpg');
    }
    &:last-of-type > div{
        background-image: url('/img/main/scroll_img3.jpg');
    }
`;

const ContentImg = styled.div`
    width: 484px;
    height: 347px;
    border-radius: 20px;
    overflow: hidden;
    background-size: cover;
    background-position-x: center;
    background-position-y:49%;
`;

const ContentText = styled.p`
    font-size: 24px;
    font-weight: 600;
    color: #191919;
    text-align: center;
    margin-top: 24px;
    line-height:1.4;
`;



// const Button = styled.div`
//     width: 200px;
//     height: 58px;
//     margin-top: 32px;
//     border-radius: 999px;
//     border: 1px solid ${colors.Black100};
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     cursor: pointer;
// `;

export default MainSecondSection;
