import styled from '@emotion/styled/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef, useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

function MainSixthSection() {
    const Title1Ref = useRef(null);
    const Title2Ref = useRef(null);
    const Img1Ref = useRef(null);
    const Img2Ref = useRef(null);
    const Img3Ref = useRef(null);
    const Img4Ref = useRef(null);
    useEffect(() => {
        gsap.to(
            Title1Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                scrollTrigger: {
                    trigger:'.sixth-title',
                    start: '30% bottom', 
                },
            },
        );

        gsap.to(
            Title2Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.3, 
                scrollTrigger: {
                    trigger:'.sixth-title',
                    start: '30% bottom', 
                },
            }
        );

        gsap.to(
            Img1Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.1, 
                scrollTrigger: {
                    trigger:'.list-wrapper',
                    start: '50% bottom', 
                },
            }
        );

        gsap.to(
            Img2Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.2, 
                scrollTrigger: {
                    trigger:'.list-wrapper',
                    start: '50% bottom', 
                },
            }
        );

        gsap.to(
            Img3Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.3, 
                scrollTrigger: {
                    trigger:'.list-wrapper',
                    start: '50% bottom', 
                },
            }
        );

        gsap.to(
            Img4Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.4, 
                scrollTrigger: {
                    trigger:'.list-wrapper',
                    start: '50% bottom', 
                },
            }
        );
    });
    return (
        <Container>
            <Section>
                <TitleWrapper className='sixth-title'>
                    <TitleBox >
                        <Title ref={Title1Ref}>
                            <span>탄탄마켓플레이스</span>는<br />
                        </Title>
                    </TitleBox>
                    <TitleBox>
                        <Title ref={Title2Ref} >기후행동을 원스톱으로 제공해요</Title>
                    </TitleBox>
                </TitleWrapper>
                <ListWrapper className='list-wrapper'>
                    <ListBox ref={Img1Ref}>
                        <ListImg/>
                        <ListText>
                            탄소발자국 계산기로<br />
                            나의 일상이 지구에 미치는<br />
                            영향을 알아보세요.
                        </ListText>
                    </ListBox>
                    <ListBox ref={Img2Ref}>
                        <ListImg/>
                        <ListText>
                            계산기에서 측정된 값을<br />
                            바탕으로 탄소배출권을<br />
                            구매할 수 있어요.
                        </ListText>
                    </ListBox>
                    <ListBox ref={Img3Ref}>
                        <ListImg/>
                        <ListText>
                            내가 구매한<br />
                            탄소배출권을 상쇄하여<br />
                            환경보호에 기여하세요.
                        </ListText>
                    </ListBox>
                    <ListBox ref={Img4Ref}>
                        <ListImg/>
                        <ListText>
                            탄소배출권을 상쇄한 후<br />
                            탄탄인증서와 글로벌 인증기관의<br />
                            인증서를 발급받아 보세요.
                        </ListText>
                    </ListBox>
                </ListWrapper>
            </Section>
        </Container>
    );
}

const Container = styled.div`
    width: 1280px;
    margin: 0 auto;
`;

const Section = styled.div`
    width: 100%;
    position: relative;
    padding-bottom: 171px;
`;

const TitleWrapper = styled.div`
    display: block;
`;

const TitleBox = styled.div`
    display: block;
    overflow: hidden;
`;

const Title = styled.h2`
    color:var(--default-text);
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.4;
    opacity: 0;
    transform: translateY(60px);
    & > span{
        color: var(--main-color);
    }
`;

const ListWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
`;

const ListBox = styled.div`
    width: calc(25% - 18px);
    height: 367px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    opacity: 0;
    transform: translateY(150px);
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.11);
    &:first-of-type > div{
        width: 85px;
        height: 108px;
        background-image: url('/img/main/icon_onestop_1.png');
    }
    &:nth-of-type(2) > div{
        width: 107px;
        height: 101px;
        background-image: url('/img/main/icon_onestop_2.png');
    }
    &:nth-of-type(3) > div{
        width: 108px;
        height: 108px;
        background-image: url('/img/main/icon_onestop_3.png');
    }
    &:last-of-type > div{
        width: 94px;
        height: 108px;
        background-image: url('/img/main/icon_onestop_4.png');
    }
`;

const ListImg = styled.div`
    background-size: cover;
`;

const ListText = styled.p`
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    color: var(--gray-scale1);
    margin-top: 35px;
`;



export default MainSixthSection;
