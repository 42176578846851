import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useNavigate } from 'react-router-dom';
import { colors } from '@styles/ui_palette';
// import { rgba } from 'emotion-rgba';
import { Axios } from '@utils/api';
import { Store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Interface
import { IBasicCard } from '@interface/common';

// Components
import BasicCard from '@components/common/BasicCard';
import CardSkeleton from '@components/common/ui/CardSkeleton';
import CategoryEmpty from '@components/common/CategoryEmpty';

gsap.registerPlugin(ScrollTrigger);

function HomeMarketPlace() {
    const Title1Ref = useRef(null);
    const Title2Ref = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [nfts, setNft] = useState<IBasicCard[]>([]);
    const [loaded, setLoaded] = useState(false);

    const notiOption = {
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 1000,
        },
    };

    const loadNFTs = async () => {
        const formData = new FormData();
        formData.append('select', 'all');

        try {
            const { data } = await Axios('marketlist', formData);
            setLoaded(true);

            if (data !== '') {
                const getData: IBasicCard[] | null = Object.values(data.marketlist_data) || null;
                if (getData) {
                    const marketList = getData.filter(
                        (market) => market.is_display === 1 && market.is_end === 0
                    );
                    const marketListUnit = marketList.slice(0, 4);

                    setNft(marketListUnit);
                }
            }
        } catch (error) {
            setLoaded(true);
            setNft([]);
            Store.addNotification({
                ...notiOption,
                title: t('Notification.errorTitle'),
                message: t('Notification.notFoundMarketList'),
                type: 'danger',
                container: 'top-left',
                insert: 'top',
            });
        }
    };

    useEffect(() => {
        gsap.to(
            Title1Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                scrollTrigger: {
                    trigger:'.market-title',
                    start: '30% bottom', 
                },
            },
        );

        gsap.to(
            Title2Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.3, 
                scrollTrigger: {
                    trigger:'.market-title',
                    start: '30% bottom', 
                },
            },
        );
        loadNFTs();
    }, []);

    return (
        <Container>
            <TitleWrapper className='market-title'>
                <TitleBox>
                    <Title ref={Title1Ref}><span>탄소배출권 구매</span>를 통해</Title>
                </TitleBox>
                <TitleBox>
                    <Title ref={Title2Ref} >지구를 위한 행동 변화를 실천하세요</Title>
                </TitleBox>
                
            </TitleWrapper>

            {loaded && nfts.length > 0 && (
                <CardWrap>
                    {nfts.map((card: IBasicCard) => (
                        <BasicCard key={card.asset_id} card={card} size={2} />
                    ))}
                </CardWrap>
            )}

            {!loaded && (
                <>
                    <CardSkeletonWrap>
                        <CardSkeleton />
                    </CardSkeletonWrap>
                    <CardSkeletonWrap>
                        <CardSkeleton />
                    </CardSkeletonWrap>
                </>
            )}

            {loaded && nfts.length === 0 && (
                <CategoryEmpty title="마켓플레이스의 배출권이 없습니다." />
            )}

            <Information>
                <MoreButton onClick={() => navigate('/marketplace')}>
                    더 많은 탄소배출권 보러가기
                    <MoreArrow />
                </MoreButton>
            </Information>
        </Container>
    );
}

const Container = styled.div`
    width: 1280px;
    margin: 0 auto;
    padding-bottom: 200px;
    /* border-bottom: 1px solid ${colors.BlueGray400}; */
`;

const TitleWrapper = styled.div`
    margin: 0 auto;
`;

const TitleBox = styled.div`
    display: block;
    overflow: hidden;
`;

const Title = styled.h2`
    color:var(--default-text);
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.4;
    opacity: 0;
    transform: translateY(60px);
    & > span{
        color: var(--main-color);
    }
`;


const Information = styled.div`
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

// const MoreButton = styled.div`
//     font-size: 14px;
//     font-weight: 500;
//     color: ${colors.Black100};
//     display: flex;
//     align-items: center;
//     transition: all 0.2s ease;
//     cursor: pointer;

//     &:hover {
//         color: ${colors.Black100};
//         text-decoration: underline;

//         ${MoreArrow} {
//             background: url('img/main/ic_black_arrow.svg');
//         }
//     }
// `;

const CardWrap = styled.div`
    display: grid;
    /* justify-content: space-between; */
    grid-template-columns: 1fr 1fr;
    /* flex-wrap: wrap; */
    margin-top: 60px;
    gap: 40px;
`;

const CardSkeletonWrap = styled.div`
    margin-top: 20px;
`;

const MoreButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 330px;
    font-size: 20px;
    color: #ffffff;
    border-radius: 500px;
    padding: 24px 36px;
    background-color: var(--main-color);
    margin-top: 53px;
    transition-duration: 0.3s;
    cursor: pointer;
    &:hover{
        transition-duration: 0.3s;
        background-color: #314AC1;
    }
`;

const MoreArrow = styled.span`
    display: inline-block;
    width: 22px;
    height: 22px;
    background-image: url('/img/main/arrow.png');
    background-size: cover;

`;

export default HomeMarketPlace;
