import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { useParams } from 'react-router-dom';
import { colors } from '@styles/ui_palette';
import { Axios } from '@utils/api';
import { Store } from 'react-notifications-component';
import { useRecoilValue } from 'recoil';
import { UserAddress } from '@recoil/auth';

interface IBurn {
    asset_id: number;
    ca: string;
    cer_name: string;
    created_at: string;
    id: number;
    memo: null;
    offset_date: string;
    offset_juno: string;
    offset_name: string;
    offset_qty: number;
    offset_use: string;
    sig_code: string;
    state: number;
    updated_at: string;
    more_info_url: string;
}

function BurnDetail() {
    const parma = useParams();
    const { burn_id } = parma;
    const userAddress = useRecoilValue(UserAddress);
    const [burnData, setBurnData] = useState<IBurn | null>(null);

    const notiOption = {
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 1000,
        },
    };

    const loadBurnNFTs = async (id: string) => {
        // 로그인 토큰 가져오기
        const token = `Bearer ${localStorage.getItem('token')}` || '';

        if (!burn_id) {
            return;
        }
        const formData = new FormData();

        formData.append('wallet_address', userAddress.address);
        formData.append('offset_req_id', id);

        try {
            const { data } = await Axios('offset_complete_ca', formData, token);

            const getData: IBurn | null = data.offset_complete_ca[0] || null;

            setBurnData(getData);
        } catch (error) {
            Store.addNotification({
                ...notiOption,
                title: '오류',
                message: '배출권 정보를 불러올 수 없습니다.',
                type: 'danger',
                container: 'top-left',
                insert: 'top',
            });
        }
    };

    // 토큰 아이디가 바뀌면 새로 NFT 정보 불러오기
    useEffect(() => {
        if (burn_id) {
            loadBurnNFTs(burn_id.toString());
        }
    }, [burn_id]);

    return (
        <>
            <Banner>
                <MyPageBanner banner="/img/myPage/mypage_banner.jpg" />
            </Banner>

            {burnData && (
                <Container>
                    <AssetBox>
                        <AssetsImage
                            src={
                                burnData.more_info_url
                                    ? burnData.more_info_url
                                    : 'https://tantan-nft-prod-upload-s3.s3.amazonaws.com/nft_asset/more_info/offset_ca.png'
                            }
                        />

                        <TextBox>
                            <BurnTitle>{burnData.ca}</BurnTitle>
                            <BurnInfo>
                                <BurnName>{burnData.cer_name}</BurnName>
                                <BurnQuantity>
                                    상쇄한 수량 : <span>{burnData.offset_qty} TON</span>
                                </BurnQuantity>
                            </BurnInfo>
                        </TextBox>
                    </AssetBox>

                    <BurnPaperBox>
                        <BurnPaperBg>
                            <BurnPaperContent>
                                <BurnImageTitle>
                                    <span>{burnData.offset_qty}</span>Tonne(s) of CO<strong>₂</strong>e Emission
                                </BurnImageTitle>
                                <BurnInfomation>
                                    <InfoTitle>프로젝트명</InfoTitle>
                                    <InfoValue>{burnData.cer_name}</InfoValue>
                                </BurnInfomation>
                                <BurnInfomation>
                                    <InfoTitle>인증번호</InfoTitle>
                                    <InfoValue>{burnData.sig_code}</InfoValue>
                                </BurnInfomation>
                                <BurnInfomation>
                                    <InfoTitle>상쇄자명</InfoTitle>
                                    <InfoValue>{burnData.offset_name}</InfoValue>
                                </BurnInfomation>
                                <BurnInfomation>
                                    <InfoTitle>상쇄일자</InfoTitle>
                                    <InfoValue>{burnData.offset_date}</InfoValue>
                                </BurnInfomation>
                                <BurnInfomation>
                                    <InfoTitle>탄소상쇄량</InfoTitle>
                                    <InfoValue>
                                        {burnData.offset_qty}
                                        Ton
                                    </InfoValue>
                                </BurnInfomation>
                                <BurnInfomation>
                                    <InfoTitle>상쇄이유</InfoTitle>
                                    <InfoValue>{burnData.offset_use}</InfoValue>
                                </BurnInfomation>
                                <BurnInfomation>
                                    <InfoTitle>인증기관</InfoTitle>
                                    <InfoValue>{burnData.ca}</InfoValue>
                                </BurnInfomation>
                                <BurnTextWrapper>
                                    <BurnText>탄소배출권이 상쇄됨으로써, <span>{burnData.offset_qty}</span>톤의 C0₂배출이 감축되었습니다.</BurnText>
                                    <BurnText>지속 가능한 미래를 위한 당신의 기후행동을 인증합니다.</BurnText>
                                </BurnTextWrapper>
                            </BurnPaperContent>
                        </BurnPaperBg>
                    </BurnPaperBox>
                </Container>
            )}
        </>
    );
}

const Container = styled.div`
    width: 980px;
    margin: 0 auto;
    margin-bottom: 60px;
`;

const Banner = styled.div`
    background-color: ${colors.Black200};
`;

const MyPageBanner = styled.div<{ banner: string }>`
    background-image: url(${(props) => props.banner});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 220px;
`;

const AssetBox = styled.div`
    margin-top: 30px;
    border:1px solid rgb(200, 205, 210);
    width: 100%;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
`;

const AssetsImage = styled('img')`
    flex-basis: 200px;
    width: 200px;
    border-radius: 15px;
    border: 1px solid ${colors.BlueGray800};
`;

const TextBox = styled.div`
    margin-left: 40px;
    flex-basis: calc(100% - 200px);
`;

const BurnTitle = styled.div`
    display: none;
`;

const BurnInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
`;

const BurnName = styled.div`
    font-size: 20px;
    font-weight: 500;
`;

const BurnQuantity = styled.div`
    font-size: 18px;
    color: ${colors.Black200};
    span {
        font-weight: 700;
        color: #109793;
    }
`;

const BurnPaperBox = styled.div`
    position: relative;
    max-width: 980px;
    margin: 40px auto;
`;

const BurnPaperBg = styled.div`
    display: flex;
    background-image: url('/img/myPage/certificate_bg.jpg');
    width: 980px;
    height: 700px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 18px 0px, rgba(0, 0, 0, 0.02) 0px -3px 0px inset;
    padding: 60px 80px;
`;

const BurnPaperContent = styled.div`
    width: 100%;
    padding-bottom: 33px;
    margin-top: auto;
`;

const BurnImageTitle = styled.div`
    margin-bottom: 30px;
    font-family: "Archivo", sans-serif;
    font-size: 25px;
    font-weight: 400;
    > span{
        font-weight: 700;
    color: #0e2490;
    font-family: "Archivo", sans-serif;
    }
    > strong{
        font-size: 25px;
        font-weight: 400;
    }
`;

const BurnInfomation = styled.div`
    display: flex;
    align-items: flex-end;
`;

const InfoTitle = styled.div`
    font-size: 15px;
    font-weight: 700;
    color: #000000;
    line-height: 28px;
    width: 106px;
`;

const InfoValue = styled.div`
    font-size: 15px;
    font-weight: 300;
    color: #404040;
    line-height: 28px;

    /* span {
        font-weight: 700;
        margin-left: 4px;
    } */
`;

const BurnTextWrapper = styled.div`
    margin-top: 75px;
`;

const BurnText = styled.div`
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    color: #777777;
    > span{
        color: #0e2490;
        font-weight: 500;
    }
`;
export default BurnDetail;
