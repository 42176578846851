import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@styles/ui_palette';
import { Axios } from '@utils/api';
import { Store } from 'react-notifications-component';
import { IBasicCard, IPaginationPrams } from '@interface/common';
import qs from 'qs';
import { useNavigate, useLocation } from 'react-router-dom';
import { CategoryListItem } from '@utils/common/category';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

// Components
import PageNavigation from '@components/common/ui/PageNavigation';
import CardSkeleton from '@components/common/ui/CardSkeleton';
import CategoryEmpty from '@components/common/CategoryEmpty';
import BasicCard from '@components/common/BasicCard';

interface ITab {
    title: string;
    active: boolean;
    type: string;
}

function MarketPlace() {
    const { t } = useTranslation();
    const [nfts, setNft] = useState<IBasicCard[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [pagination, setPagination] = useState<number>(() => 1);
    const [pagenationInfo, setPagenationInfo] = useState<IPaginationPrams | null>(null);
    const navigate = useNavigate();
    const [tabItems, setTabItems] = useState<ITab[]>(CategoryListItem());
    const location = useLocation();

    const notiOption = {
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 1000,
        },
    };

    const handlePageChange = async (page: number) => {
        setPagination(page);

        window.scrollTo(0, 370);
        const newTabItems = tabItems.find((tabItem: ITab) => tabItem.active);

        if (newTabItems) {
            await navigate(`/marketplace?category=${newTabItems.type}&page=${page}`);

            loadCategory();
        }
    };

    const handleChangeTab = async (item: ITab) => {
        const newTabItems = tabItems.map((tabItem: ITab): ITab => {
            const current = JSON.parse(JSON.stringify(tabItem));
            current.active = false;

            if (item.type === current.type) {
                current.active = true;
            }

            return current;
        });

        setTabItems(newTabItems);
        setPagination(1);
        setPagenationInfo(null);
        setNft([]);

        await navigate(`/marketplace?category=${item.type}&page=1`);

        loadCategory();
    };

    const loadCategory = async () => {
        setLoaded(false);
        const formData = new FormData();
        formData.append('select', 'all');

        const getParams = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        if (getParams.category && getParams.category !== 'All') {
            formData.append('category', getParams.category.toString());
        }

        if (getParams.page) {
            setPagination(Number(getParams.page));
        }

        try {
            const { data, success } = await Axios(
                `marketlist?page=${getParams.page ? getParams.page : 1}`,
                formData
            );

            setLoaded(true);

            if (success && data !== '') {
                const getData: IBasicCard[] | null = Object.values(data.marketlist_data) || null;
                if (getData) {
                    await setPagenationInfo(data.pagination_info);
                    setNft(getData);
                }
            }
        } catch (error) {
            setLoaded(true);
            setNft([]);
            Store.addNotification({
                ...notiOption,
                title: t('Notification.errorTitle'),
                message: t('Notification.notFoundMarketList'),
                type: 'danger',
                container: 'top-left',
                insert: 'top',
            });
        }
    };

    useEffect(() => {
        const getParams = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        const newTabItems = tabItems.map((tabItem: ITab): ITab => {
            const current = JSON.parse(JSON.stringify(tabItem));
            current.active = false;

            if (!getParams.category && current.type === 'All') {
                current.active = true;
            }

            if (getParams.category === current.type) {
                current.active = true;
            }

            return current;
        });
        setTabItems(newTabItems);
        loadCategory();
    }, [location]);

    return (
        <>
            <Helmet>
                <title>배출권 구매 | 탄탄 마켓플레이스</title>
                <meta property="og:site_name" content="배출권 구매 | 탄탄 마켓플레이스"/>
                <meta name='description' content='탄탄 마켓플레이스에서 탄소배출권을 구매하고 미래를 위한 기후행동에 참여하세요.' />
                <meta property='og:url' content='https://tantanmarketplace.com/marketplace' />
                <meta property='og:type' content='website' />
                <meta property="og:image" content="%PUBLIC_URL%/img_og_image.png"/>
                <meta property='og:title' content='TANTAN MARKETPLACE | 탄탄 마켓플레이스' />
                <meta property='og:description' content='탄탄 마켓플레이스에서 탄소배출권을 구매하고 미래를 위한 기후행동에 참여하세요.' />
                <link rel="canonical" href="https://tantanmarketplace.com/marketplace" />
            </Helmet>
            <Container>
                <Title>마켓플레이스</Title>
                <SubTitle>
                    탄탄 마켓플레이스에서 탄소배출권을 구매하고 미래를 위한 기후행동에 참여하세요.
                </SubTitle>

                <AssetsTab>
                    {tabItems.map((item, index) => (
                        <TabItem
                            key={`marketplace-tab-${item.type}-${index}`}
                            active={item.active}
                            onClick={() => handleChangeTab(item)}
                        >
                            {item.title}
                        </TabItem>
                    ))}
                </AssetsTab>

                {loaded &&
                    tabItems.map((item, index) => (
                        <CardBox key={`card-section-${index}`}>
                            {item.active && (
                                <NFTCardWrapper key={`card-${index}`}>
                                    {nfts.map((card: IBasicCard, index) => (
                                        <BasicCard
                                            key={`card-${index}-${card.asset_id}`}
                                            card={card}
                                            size={5}
                                        />
                                    ))}
                                </NFTCardWrapper>
                            )}
                        </CardBox>
                    ))}

                {!loaded && (
                    <>
                        <CardSkeletonWrap>
                            <CardSkeleton />
                        </CardSkeletonWrap>
                        <CardSkeletonWrap>
                            <CardSkeleton />
                        </CardSkeletonWrap>
                        <CardSkeletonWrap>
                            <CardSkeleton />
                        </CardSkeletonWrap>
                        <CardSkeletonWrap>
                            <CardSkeleton />
                        </CardSkeletonWrap>
                    </>
                )}

                {loaded && pagenationInfo && pagenationInfo.last_page > 1 && (
                    <PageNavigation
                        activePage={pagination}
                        itemsCountPerPage={20}
                        totalItemsCount={pagenationInfo.total}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                )}

                {nfts.length === 0 && <CategoryEmpty title="마켓플레이스에 등록된 배출권이 없습니다." />}
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 1280px;
    padding: 60px 0 100px 0;
    margin: 0 auto;
    position: relative;
    z-index: 2;
`;

const Title = styled.div`
    font-size: 28px;
    font-weight: 700;
`;

const SubTitle = styled.div`
    font-size: 14px;
    color: ${colors.Black100};
    margin-top: 20px;
`;

const CardBox = styled.div``;

const AssetsTab = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;
`;

const TabItem = styled.div<{ active: boolean }>`
    flex-basis: 116px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: ${(props) => (props.active ? '#0e2490' : colors.Black100)};
    border: 1px solid ${(props) => (props.active ? '#0e2490' : colors.BlueGray400)};
    border-radius: 999px;
    margin-right: 8px;
    cursor: pointer;

    &:hover {
        background-color: ${colors.BlueGray300};
    }
`;

const NFTCardWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 60px;
    gap: 40px;
`;

const CardSkeletonWrap = styled.div`
    margin-top: 44px;
`;

export default MarketPlace;
