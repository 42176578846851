// import React from 'react';
import styled from '@emotion/styled/macro';
import { gsap } from 'gsap';
import { useRef, useEffect } from 'react';

function MainBanner() {
    const Title1Ref = useRef(null);
    const Title2Ref = useRef(null);
    const SubTitleRef = useRef(null);
    useEffect(() => {
        gsap.to(
            Title1Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.4, 
                ease: 'ease' 
            });

        gsap.to(
            Title2Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.7, 
                ease: 'ease' 
            });

        gsap.to(
            SubTitleRef.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 1.2, 
                ease: 'ease' 
            });
    }, []);

    return (
        <Container>
            <TitleWrapper className='hide'>
                {/* <Title ref={TextRef}>누구나 환경을 지킬 수 있도록,</Title> */}
                <TitleBox>
                    <Title ref={Title1Ref}>누구나 환경을 지킬 수 있도록,</Title>
                </TitleBox>
                <TitleBox>
                    <Title ref={Title2Ref}>작은 실천을 만드는 탄소배출권 플랫폼</Title>
                </TitleBox>
            </TitleWrapper>
            <SubTitle ref={SubTitleRef}>
                탄탄마켓플레이스
            </SubTitle>
            <ScrollDown>
                <ScrollDownImg className='bounce'/>
                scroll down</ScrollDown>
            <MainImage />
        </Container>
    );
}

const Container = styled.div`
    width: 1280px;
    margin: 0 auto;
    padding-top: 100px;
    min-height: 927px;
    background-color: #ffffff;
    position: relative;
`;

const TitleWrapper = styled.div`
    display: block;
`;

const TitleBox = styled.div`
    display: block;
    overflow: hidden;
    min-height: 60px;
`;

const Title = styled.h1`
    color:var(--default-text);
    text-align: left;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.3;
    opacity: 0;
    transform: translateY(60px);
`;

const SubTitle = styled.span`
    display: inline-block;
    color:#ffffff;
    font-size: 45px;
    font-weight: 600;
    margin-top: 28px;
    background-color: var(--main-color);
    padding: 10px 20px;
    border-radius: 13px;
    user-select: none;
    opacity: 0;
    transform: translateY(-40px);
`;

const MainImage = styled.div`
    position: absolute;
    right: -95px;
    bottom: 0;
    background-image:url('/img/main/visual.png');
    background-size: cover;
    width: 730px;
    height: 714px;
    border: 0;
`;

const ScrollDown = styled.div`
    position: absolute;
    left: 0;
    bottom: 115px;
    display: flex;
    align-items: center;
    padding-left: 2px;
    width: 126px;
    height: 24px;
    font-size: 20px;
    color: var(--default-text);
    font-weight: 300;
    user-select: none;
`;

const ScrollDownImg = styled.div`
    width: 22px;
    height: 22px;
    background-image:url('/img/main/icon_visual_arrow.png');
    background-repeat: no-repeat;
    background-size: cover;
    user-select: none;
    margin-right: 2px;
`;


// const PizzlyImageBox = styled.div`
//     text-align: center;
//     position: absolute;
//     bottom: -35px;
//     left: 50%;
//     transform: translate(-50%, 0);
//     z-index: 2;
// `;

// const PizzlyImage = styled('img')``;

// const SlideBox = styled.div`
//     width: 100%;
//     position: absolute;
//     left: 50%;
//     top: 70%;
//     transform: translate(-50%, -50%);
//     overflow: hidden;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     z-index: 1;

//     /* 첫 번째 배너 리스트가 끝나면 뒤에 바로 두 번째 배너 리스트가 붙도록 설정 */
//     .n1:last-child {
//         animation-delay: 25s;
//     }
// `;

// const SlideList = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     animation: roll 25s linear infinite;

//     /* 롤링 애니메이션 정의 */
//     @keyframes roll {
//         0% {
//             transform: translateX(0);
//         }
//         100% {
//             transform: translateX(-100%);
//         }
//     }
// `;

// const SlideItems = styled.div`
//     margin: 0 60px;
// `;

// const SlideImage = styled('img')`
//     opacity: 0.6;
// `;

export default MainBanner;
