export const List = () => [
    {
        title: '회사소개',
        item: [
            {
                name: 'About e-ETS',
                url: 'https://e-ets.org/',
                type: 'out',
                bold: false,
            },
            //         // {
            //         //     name: '채용공고',
            //         //     url: '',
            //         //     type: 'out',
            //         //     bold: false,
            //         // },
        ],
    },
    {
        title: '카테고리',
        item: [
            {
                name: '가스',
                url: '/marketplace?category=가스&page=1',
                type: 'in',
                bold: false,
            },
            {
                name: '풍력',
                url: '/marketplace?category=풍력&page=1',
                type: 'in',
                bold: false,
            },
            {
                name: '수력',
                url: '/marketplace?category=수력&page=1',
                type: 'in',
                bold: false,
            },
            {
                name: '쿡스토브',
                url: '/marketplace?category=쿡스토브&page=1',
                type: 'in',
                bold: false,
            },
            {
                name: '태양열',
                url: '/marketplace?category=태양열&page=1',
                type: 'in',
                bold: false,
            },
            {
                name: '산림경영',
                url: '/marketplace?category=산림경영&page=1',
                type: 'in',
                bold: false,
            },
            {
                name: 'REDD+',
                url: '/marketplace?category=REDD&page=1',
                type: 'in',
                bold: false,
            },
            {
                name: '블루카본',
                url: '/marketplace?category=블루카본&page=1',
                type: 'in',
                bold: false,
            },
        ],
    },
    // {
    //     title: '서비스',
    //     item: [
    //         {
    //             name: '마이페이지',
    //             url: '/mypage',
    //             type: 'in',
    //             bold: false,
    //         },
    //         {
    //             name: '배출권 만들기',
    //             url: '/create',
    //             type: 'in',
    //             bold: false,
    //         },
    //     ],
    // },
    {
        title: '고객지원',
        item: [
            {
                name: '고객센터',
                url: 'https://tantanm.zendesk.com/hc/ko',
                type: 'out',
                bold: true,
            },
            {
                name: '공지사항',
                url: 'https://tantanm.zendesk.com/hc/ko/categories/8382598018575-%EA%B3%B5%EC%A7%80%EC%82%AC%ED%95%AD',
                type: 'out',
                bold: true,
            },
            {
                name: '1:1 문의',
                url: 'https://tantanm.zendesk.com/hc/ko/requests/new',
                type: 'out',
                bold: true,
            },
            {
                name: '자주 묻는 질문',
                // url: 'https://tantanm.zendesk.com/hc/ko/categories/8382598158863-%ED%83%84%ED%83%84%EB%A7%88%EC%BC%93-%EC%9D%B4%EC%9A%A9%EC%95%88%EB%82%B4',
                url: 'https://tantanm.zendesk.com/hc/ko/categories/8382549899023-%EC%9E%90%EC%A3%BC-%EB%AC%BB%EB%8A%94-%EC%A7%88%EB%AC%B8',
                type: 'out',
                bold: true,
            },
        ],
    },
    {
        title: '회사정책',
        item: [
            {
                name: '이용약관',
                url: 'https://tantanm.zendesk.com/hc/ko/articles/8382723381519-%ED%83%84%ED%83%84%EB%A7%88%EC%BC%93%ED%94%8C%EB%A0%88%EC%9D%B4%EC%8A%A4-%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80',
                type: 'out',
                bold: true,
            },
            {
                name: '개인정보처리방침',
                url: 'https://tantanm.zendesk.com/hc/ko/articles/8382838240015-%ED%83%84%ED%83%84%EB%A7%88%EC%BC%93%ED%94%8C%EB%A0%88%EC%9D%B4%EC%8A%A4-%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8',
                type: 'out',
                bold: true,
            },
            // {
            //     name: '가스비 안내',
            //     url: 'https://tantanm.zendesk.com/knowledge/editor/01HFC48QXX5P9Q610ZCGNMVMXG/ko?brand_id=8382444922511',
            //     type: 'out',
            //     bold: false,
            // },
        ],
    },
];

export const Partner = ['1', '2', '3', '4', '5'];

export const Sns = [
    // {
    //     name: 'twitter',
    //     link: 'https://twitter.com/Coconut_Global',
    // },
    // {
    //     name: 'discode',
    //     link: 'https://discord.com/invite/sqQSK54ZY2',
    // },
    // {
    //     name: 'instargram',
    //     link: 'https://www.instagram.com/coconut_global/',
    // },
    // {
    //     name: 'naver_blog',
    //     link: 'https://blog.naver.com/TanTan',
    // },
    // {
    //     name: 'telegram',
    //     link: 'https://t.me/Coconut_Global_official',
    // },
    // {
    //     name: 'midium',
    //     link: 'https://medium.com/@TanTan.io',
    // },
    // {
    //     name: 'mail',
    //     link: 'beodix@beodix.com',
    // },
];
