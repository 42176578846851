import styled from '@emotion/styled/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef, useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

function MainFifthSection() {
    const Title1Ref = useRef(null);
    const Title2Ref = useRef(null);
    const Text2Ref = useRef(null);
    const RecycleImg1Ref = useRef(null);
    const RecycleImg2Ref = useRef(null);
    const RecycleImg3Ref = useRef(null);
    useEffect(() => {
        gsap.to(
            Title1Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                scrollTrigger: {
                    trigger:'.fifth-title',
                    start: '30% bottom', 
                },
            },
        );

        gsap.to(
            Title2Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.3, 
                scrollTrigger: {
                    trigger:'.fifth-title',
                    start: '30% bottom', 
                },
            }
        );
        
        gsap.to(
            Text2Ref.current, 
            0.7, 
            { 
                opacity:1,
                delay:0.65,
                scrollTrigger: {
                    trigger:'.fifth-text',
                    start: '50% bottom', 
                },
            }
        );

        gsap.to(
            RecycleImg1Ref.current, 
            0.3, 
            { 
                transform: 'scale(1)', 
                opacity:1,
                delay: 0.5, 
                scrollTrigger: {
                    trigger:'.recycle-img',
                    start: '20% bottom',   
                },
            }
        );

        gsap.to(
            RecycleImg2Ref.current, 
            0.3, 
            { 
                transform: 'scale(1)', 
                opacity:1,
                delay: 0.8,
                scrollTrigger: {
                    trigger:'.recycle-img',
                    start: '20% bottom',
                },
            }
        );   

        gsap.to(
            RecycleImg3Ref.current, 
            0.3, 
            { 
                transform: 'scale(1)', 
                opacity:1,
                delay: 0.7, 
                scrollTrigger: {
                    trigger:'.recycle-img',
                    start: '20% bottom',
                },
            }
        );   
    }, []);
    return (
        <Container>
            <Section>
                <TitleWrapper className='fifth-title'>
                    <TitleBox>
                        <Title ref={Title1Ref}>
                            <span>탄소배출권</span>은 지구온난화를 막는<br />
                        </Title>
                    </TitleBox>
                    <TitleBox>
                        <Title ref={Title2Ref} >가장 확실하고 간편한 방법이에요</Title>
                    </TitleBox>
                </TitleWrapper>
                <Description ref={Text2Ref} className='fifth-text'>
                    환경을 보호하기 위해 우리가 할 수 있는 방법은 여러 가지가 있지만,<br />
                    환경에 미치는 그 영향은 한계가 있을 수밖에 없어요.<br />
                    하지만 탄소배출권을 구매하고&nbsp; 
                    <PopupTrigger>
                        <PopupText>상쇄</PopupText>
                        <PopupBox>
                            <PopupBoxTitle>
                                <PopupBoxImg />
                                <span>상쇄</span>란 무엇인가요?
                            </PopupBoxTitle>   
                            <PopupBoxTextWrapper>
                                <PopupBoxText>상쇄란 기업이나 개인이 자신의 탄소 배출량을 줄이기 위해<br /> 배출권 프로젝트를 지원하는 것을 의미해요.</PopupBoxText>
                                <PopupBoxText>이를 통해 지구 온난화의 주범인<br /> 온실가스의 발생을 줄이는 데 기여할 수 있어요.</PopupBoxText>
                            </PopupBoxTextWrapper>
                        </PopupBox>
                    </PopupTrigger>
                    한다면 효과적으로 탄소를 줄일 수 있게 돼요.
                </Description>
                <RecycleWrapper>
                    <RecycleImg className='recycle-img'/>
                    <RecyclePopup1 ref={RecycleImg1Ref}>일회용품 사용 및<br />쓰레기 배출</RecyclePopup1>
                    <RecyclePopup2 ref={RecycleImg2Ref}> 기후행동 및<br />탄소배출권 구매</RecyclePopup2>
                    <RecyclePopup3 ref={RecycleImg3Ref}>상쇄 및 인증을 통한<br />환경보호</RecyclePopup3>
                </RecycleWrapper>
            </Section>
        </Container>
    );
}

const Container = styled.div`
    width: 1280px;
    margin: 0 auto;
    padding-top: 398px;
`;

const Section = styled.div`
    width: 100%;
    position: relative;
    padding-bottom: 228px;
`;

const TitleWrapper = styled.div`
    display: block;
`;

const TitleBox = styled.div`
    display: block;
    overflow: hidden;
`;

const Title = styled.h2`
    color:var(--default-text);
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.4;
    opacity: 0;
    transform: translateY(60px);
    & > span{
        color: var(--main-color);
    }
`;

const Description = styled.p` 
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    margin-top: 40px;
    line-height: 1.5;
    opacity: 0;
`;

const PopupTrigger = styled.span`
    position: relative;
    &:hover > div{
        opacity: 1;
        width: 452px;
        height: auto;
        transition:opacity 0.4s ease-in;
    }
`;

const PopupText = styled.p`
    display: inline-block;
    color:var(--main-color);
    font-weight: 600;
    text-underline-offset: 5px;
    text-decoration: underline;
    cursor: pointer;
`;  

const PopupBox = styled.div`
    position: absolute;
    left:0;
    top:100%;
    width: 0px;
    height: 0px;
    opacity: 0;
    margin-top: 4px;
    padding: 30px 25px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.11);
    z-index: 10;
`;

const PopupBoxTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    line-height: auto;
    margin-bottom:24px;
    & > span{
        color: var(--main-color);
    }
`;
const PopupBoxImg = styled.div`
    width: 30px;
    height: 30px;
    background-image: url('/img/main/info_icon.png');
    background-position: center;
    background-size: cover;
    margin-right: 14.5px;
`;

const PopupBoxTextWrapper = styled.div`
    padding-left: 44.5px;
`;

const PopupBoxText = styled.div`
    font-size: 16px;
    font-weight: 300;
    color: var(--gray-scale1);
    line-height: 1.4;
    text-align: left;
    &:first-of-type{
        margin-bottom: 19px;
    }
`;

const RecycleWrapper = styled.div`
    position: relative;
    width: 600px;
    margin: 0 auto;
    margin-top: 79px;
`;

const RecycleImg = styled.div`
    margin: 0 auto;
    width: 100%;
    height: 600px;
    background-image: url('/img/main/illust_recycle.png');
    background-size: cover;
    background-position: center;
`;

const RecyclePopup1 = styled.span`
    position: absolute;
    top:49px;
    right: -112px;
    padding: 23px 28px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    color: var(--default-text);
    text-align: center;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.11);
    user-select: none;
    opacity: 0;
    transform: scale(0);
`;  

const RecyclePopup2 = styled.span`
    position: absolute;
    left: 175px;
    bottom: -83px;
    padding: 23px 28px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    color: var(--default-text);
    text-align: center;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.11);
    user-select: none;
    opacity: 0;
    transform: scale(0);
`;  

const RecyclePopup3 = styled.span`
    position: absolute;
    left:-124px;
    top:119px;
    padding: 23px 28px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    color: var(--default-text);
    text-align: center;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.11);
    user-select: none;
    opacity: 0;
    transform: scale(0);
`;  

export default MainFifthSection;
