import React from 'react';
import styled from '@emotion/styled/macro';
import { useNavigate } from 'react-router-dom';
import { List } from '@utils/common/footer';
import { useRecoilValue } from 'recoil';

// recoil
import { UserAddress } from '@recoil/auth';

function FooterGnb() {
    const userAddress = useRecoilValue(UserAddress);
    const navigate = useNavigate();
    const footerList = List();
    // const handleLogo = () => navigate('/');
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const handdleLink = (category: any) => {
        if (category.type === 'out') {
            window.open(category.url);
            return;
        }

        if (category.url === '/mypage') {
            if (userAddress.address === '') {
                window.location.assign(`/login?location=${window.location.pathname}`);
                return;
            }
            navigate(`${category.url}/${userAddress.address}`);
            return;
        }

        if (category.url === '/create') {
            if (userAddress.address === '') {
                window.location.assign(`/login?location=${window.location.pathname}`);
                return;
            }
            navigate(category.url);
            return;
        }

        navigate(category.url);
    };

    return (
        <FooterGnbSection>
            <FooterGnbContainer>
                <FooterTop>
                    <CategoryGroup>
                        {footerList.map((categoryItems, index) => (
                            <CategoryItem key={`${index}-${categoryItems.title}`}>
                                <CategoryTitle>{categoryItems.title}</CategoryTitle>
                                <GnbList>
                                    {categoryItems.item.map((category) => (
                                        <Category key={`${index}-${category.name}-category`}>
                                            <CategoryLink
                                                onClick={() => handdleLink(category)}
                                                isBold={category.bold}
                                            >
                                                {category.name}
                                            </CategoryLink>
                                        </Category>
                                    ))}
                                </GnbList>
                            </CategoryItem>
                        ))}
                    </CategoryGroup>
                </FooterTop>
                <FooterBottom>
                    <Logo>
                        <LogoImage src="/img/layout/footer/large_logo.png" alt="Tantan Marketplace" />
                    </Logo>
                    <CopyrightWrapper>
                        <TopButton onClick={scrollToTop}>Back to top ↑</TopButton>
                        <CopyrightText>©2023 e-ETS CO.LTD. All rights reserved.</CopyrightText>
                    </CopyrightWrapper>
                </FooterBottom>
            </FooterGnbContainer>
        </FooterGnbSection>
    );
}

const Logo = styled.div`
    width: 400px;
    position: relative;
`;

const LogoImage = styled('img')`
    width: 100%;
`;

const FooterGnbSection = styled.div`
    padding-bottom: 50px;
    background-color: #f9f9f9;
`;

const FooterGnbContainer = styled.footer`
    width: 1280px;
    padding-top: 36px;
    border-top: 1px solid #e6e6e6;
    margin: 0 auto;
`;

const FooterTop = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const FooterBottom = styled.div`
    display:flex;
    justify-content: space-between;
`;


const CopyrightWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

const TopButton = styled.p`
    font-size: 12px;
    font-weight: 500;
    opacity: 0.5;
    cursor: pointer;
`;

const CopyrightText = styled.p`
    font-size: 12px;
    font-weight: 500;
    opacity: 0.5;
    margin-top: 24px;
    margin-left: 160px;
`;

const CategoryGroup = styled.div`
    display: inline-flex;
    margin-left: auto;
    justify-content: flex-end;
`;

const CategoryItem = styled.div`
    width: 96px;
    margin-left: 20px;
`;

const CategoryTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
`;

const GnbList = styled.div`
    margin-top: 12px;
`;

const Category = styled.div`
    margin-bottom: 10px;
`;

const CategoryLink = styled.span<{ isBold: boolean }>`
    font-size: 14px;
    color: #474747;
    cursor: pointer;
    transition: all 0.2s ease;
    /* font-weight: ${(props) => (props.isBold ? '500' : '300')}; */
    font-weight: 500;

    &:hover {
        transition: all 0.2s ease;
        text-decoration: underline;
    }
`;

export default FooterGnb;
