/* eslint-disable @typescript-eslint/indent */
/* eslint-disable indent */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { PaymentWidgetInstance } from '@tosspayments/payment-widget-sdk';
import styled from '@emotion/styled/macro';
import moment from 'moment';
import { colors } from '@styles/ui_palette';
import { useNavigate } from 'react-router-dom';
import { IAssetsDetail } from '@interface/assets';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Store } from 'react-notifications-component';
import { useChain } from '@utils/interact';
import qs from 'qs';
import usePaymentWidget from '@utils/usePaymentWidget';

// recoil
import { UserAddress, Chain } from '@recoil/auth';
import { rgba } from 'emotion-rgba';

const selector = '#payment-widget';

const customerKey = '-oX_VxaJPm0DKzIDfm_Ev';
const clientKey = process.env.REACT_APP_TOSS_PAYMENTS_Client_Key?.toString() || '';

interface IAssetsTitlePanel {
    nftAssets: IAssetsDetail;
    assetId: number;
    setFormCheck: any;
    quantity: number;
    isFirst: boolean;
    handleMarketCancel: (nft: IAssetsDetail) => void;
}

interface ITossOrder {
    assets: IAssetsDetail;
    quantity: number;
}

function AssetsOrderPanel({
    nftAssets,
    assetId,
    setFormCheck,
    quantity,
    handleMarketCancel,
    isFirst,
}: IAssetsTitlePanel) {
    const { t } = useTranslation();
    const userAddress = useRecoilValue(UserAddress);
    const chainId = useRecoilValue(Chain);
    const navigate = useNavigate();
    const [cancelDisabled, setCancelDisabled] = useState<boolean>(false);
    const [endTime, setEndTime] = useState<boolean>(false);
    const [tosModal, setTossModal] = useState<boolean>(false);

    const { data: paymentWidget } = usePaymentWidget(clientKey, customerKey);
    const paymentMethodsWidgetRef = useRef<ReturnType<
        PaymentWidgetInstance['renderPaymentMethods']
    > | null>(null);
    const [price, setPrice] = useState(0);
    const [paymentMethodsWidgetReady, isPaymentMethodsWidgetReady] = useState(false);

    const notiOption = {
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 2000,
        },
    };

    // 토스 간편 결제
    const handleTossPaments = useCallback(async ({ assets, quantity }: ITossOrder) => {
        if (!userAddress.isCertification) {
            Store.addNotification({
                ...notiOption,
                title: '회원정보 입력',
                message: '구매에 필요한 필수항목을 입력해주세요.',
                type: 'danger',
                container: 'top-left',
                insert: 'top',
                dismiss: {
                    duration: 5000,
                },
            });
            navigate(`/mypage/edit?login=true&location=${window.location.pathname}`);
            return;
        }

        if (assets.adult_gb.toString() === '1' && userAddress.adult.toString() === '0') {
            Store.addNotification({
                ...notiOption,
                title: '성인인증 컨텐츠',
                message: '미성년자는 구매하실수 없습니다.',
                type: 'danger',
                container: 'top-left',
                insert: 'top',
            });
            return;
        }

        if ((chainId && !useChain[chainId]) || useChain[chainId].symbol !== nftAssets.symbol) {
            const changeChain = Object.values(useChain).find(
                (chain) => chain.symbol === nftAssets.symbol
            );

            if (changeChain) {
                return;
            }
        }

        if (assets.symbol !== 'KRW') {
            setFormCheck(true);
            return;
        }

        // Recoil에 저장해줘야
        // 주문완료페이지에서 데이터를 보여줄수 있음
        const params = qs.stringify({
            assetId,
            quantity,
            isFirst,
            gb: assets.pfp_gb,
        });
        localStorage.setItem('tossPaymentsOrderInfo', params);

        setTossModal(true);
    }, []);

    const handlePay = useCallback(async () => {
        console.log('123');
        try {
            await paymentWidget?.requestPayment({
                orderId: `${nftAssets.market_id}-${
                    nftAssets.asset_id
                }-${quantity}-${moment().format('YYYY-MM-DD-HH_mm_ss')}`,
                orderName: nftAssets.asset_name,
                customerName: userAddress.name,
                successUrl: `${window.location.origin}/toss-payments-complete`,
                failUrl: `${window.location.origin}`,
            });
        } catch (error) {
            // 에러 처리하기
            console.error(error);
        }
    }, [paymentWidget]);

    // 마켓 취소 이벤트
    const handleCancel = async (nft: IAssetsDetail) => {
        // 클릭하면 버튼 비활성화
        setCancelDisabled(true);

        try {
            // 취소 함수
            await handleMarketCancel(nft);

            // 취소가 완료되면 버튼 활성화
            setCancelDisabled(false);
        } catch (error) {
            // 취소가 완료되면 버튼 활성화
            setCancelDisabled(false);
        }
    };

    useEffect(() => {
        if (nftAssets.expiration_date) {
            const nowTime = new Date().getTime();
            const assetTime = new Date(nftAssets.expiration_date.split(' ')[0]).getTime();

            if (nowTime > assetTime) {
                setEndTime(true);
            }
        }
    }, []);

    useEffect(() => {
        if (paymentWidget == null) {
            return;
        }

        if (!tosModal) {
            return;
        }

        // ------  결제 UI 렌더링 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
        const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
            selector,
            { value: price },
            { variantKey: 'DEFAULT' }
        );

        //  ------  결제 UI 렌더링 완료 이벤트 ------
        paymentMethodsWidget.on('ready', () => {
            paymentMethodsWidgetRef.current = paymentMethodsWidget;
            isPaymentMethodsWidgetReady(true);
        });
    }, [paymentWidget, tosModal]);

    useEffect(() => {
        const paymentMethodsWidget = paymentMethodsWidgetRef.current;
        setPrice(nftAssets.price * Number(quantity));

        if (paymentMethodsWidget == null) {
            return;
        }

        // ------ 금액 업데이트 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#updateamount결제-금액
        paymentMethodsWidget.updateAmount(price);
    }, [price]);

    return (
        <Container>
            {/* <Widget id="payment-widget" /> */}
            {/* 구매 버튼 */}
            {nftAssets.price !== 0 &&
                userAddress.address !== '' &&
                nftAssets.is_display === 1 &&
                userAddress.address !== nftAssets.owner_address && (
                    <OrderBox>
                        {!endTime && nftAssets.symbol !== 'ETH' && (
                            <Order
                                type="button"
                                onClick={() =>
                                    handleTossPaments({
                                        assets: nftAssets,
                                        quantity,
                                    })
                                }
                            >
                                {nftAssets.symbol === 'KRW' && (
                                    <TossIcon src="/img/assets_detail/ic_toss_white.svg" />
                                )}
                                {nftAssets.symbol === 'KRW'
                                    ? '구매하기'
                                    : t('AssetsDetail.orderButton')}
                            </Order>
                        )}
                        {(endTime || nftAssets.symbol === 'ETH') && (
                            <Order type="button" disabled>
                                판매기간 종료
                            </Order>
                        )}
                    </OrderBox>
                )}
            {/* 로그인 전 구매 버튼 */}
            {nftAssets.price !== 0 &&
                userAddress.address === '' &&
                nftAssets.is_display === 1 &&
                userAddress.address !== nftAssets.owner_address && (
                    <OrderBox>
                        <Order
                            type="button"
                            onClick={() =>
                                window.location.assign(
                                    `/login?location=${window.location.pathname}`
                                )
                            }
                        >
                            {t('AssetsDetail.loginButton')}
                        </Order>
                    </OrderBox>
                )}
            {/* 내서비스인 경우 */}
            {nftAssets.price !== 0 &&
                nftAssets.is_display === 1 &&
                userAddress.address === nftAssets.owner_address && (
                    <OrderBox>
                        <OrderWhite
                            type="button"
                            onClick={() => handleCancel(nftAssets)}
                            disabled={cancelDisabled}
                        >
                            판매취소
                        </OrderWhite>
                    </OrderBox>
                )}
            {/* 판매 완료된 경우 */}
            {nftAssets.price !== 0 &&
                nftAssets.is_display === 0 &&
                userAddress.address !== nftAssets.owner_address && (
                    <OrderBox>
                        <Order disabled>{t('AssetsDetail.saleCompleteButton')}</Order>
                    </OrderBox>
                )}
            {/* 재판매 버튼 */}
            {Number(userAddress.vip_gb) >= 1 &&
                (!nftAssets.is_display || nftAssets.is_display === 0) &&
                userAddress.address === nftAssets.owner_address && (
                    <OrderBox>
                        <Order
                            type="button"
                            onClick={() =>
                                navigate(
                                    `/market-sale/${nftAssets.contract_address}/${nftAssets.asset_id}`
                                )
                            }
                        >
                            {t('AssetsDetail.resellButton')}
                        </Order>
                    </OrderBox>
                )}

            {tosModal && (
                <Modal>
                    <Body>
                        <Widget id="payment-widget" />
                        <OrderBox>
                            <Order
                                type="button"
                                disabled={!paymentMethodsWidgetReady}
                                onClick={handlePay}
                            >
                                결제하기
                            </Order>
                        </OrderBox>

                        <OrderBox>
                            <OrderWhite
                                type="button"
                                onClick={() => setTossModal(false)}
                                disabled={cancelDisabled}
                            >
                                결제취소
                            </OrderWhite>
                        </OrderBox>
                    </Body>
                </Modal>
            )}
        </Container>
    );
}

const Modal = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${rgba(colors.Black100, 0.8)};
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Widget = styled.div``;

const Body = styled.div`
    margin-top: 120px;
    width: 600px;
    max-height: 600px;
    background-color: white;
    padding: 30px;
    border-radius: 12px;
`;

const Container = styled.div``;

const TossIcon = styled('img')`
    margin-right: 8px;
    max-width: 18px;
`;

const OrderBox = styled.div`
    margin-top: 12px;
`;

const Order = styled.button`
    width: 100%;
    height: 58px;
    border-radius: 8px;
    font-size: 16px;
    border: 0;
    background-color: ${colors.Black200};
    color: ${colors.White100};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:disabled {
        background-color: ${colors.BlueGray500};
        cursor: not-allowed;
    }
`;

const OrderWhite = styled.button`
    width: 100%;
    height: 58px;
    border-radius: 8px;
    font-size: 16px;
    border: 1px solid ${colors.BlueGray500};
    background-color: ${colors.White100};
    color: ${colors.Black100};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: ${rgba(colors.BlueGray500, 0.2)};
        border-color: transparent;
    }

    &:disabled {
        border-color: transparent;
        background-color: ${colors.BlueGray200};
        color: ${colors.BlueGray500};
        cursor: not-allowed;
    }
`;

export default AssetsOrderPanel;
