import React from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@styles/ui_palette';

// Data
import { burnFormData, Iburndata } from '@utils/data/profile_edit_data';

// Components
import Input from '@components/common/ui/Input';
import Select from '@components/common/ui/Select';

interface IEditForm {
    register: any;
    errors: any;
    setValue: any;
    getValues: any;
    watch: any;
}

function BurnForm({ register, errors, setValue, getValues, watch }: IEditForm) {
    const editForm: Iburndata[] = burnFormData();

    return (
        <>
            {editForm.map((form: Iburndata) => (
                <FormBox key={form.label}>
                    <TitleBox type={form.label}>
                        {/* TITLE */}
                        <Title>
                            {form.required && <span>(필수)</span>}
                            {form.title}
                        </Title>
                    </TitleBox>

                    {/* TEXT FORM */}
                    {(form.type === 'text' || form.type === 'number') && (
                        <InputBox>
                            <Input
                                register={register}
                                required={form.required}
                                minLength={form.minLength}
                                maxLength={form.maxLength}
                                valueLength={watch(form.label) ? watch(form.label).length : 0}
                                getValue={getValues(form.label)}
                                setValue={setValue}
                                error={!!errors[form.label]}
                                placeholder={form.message}
                                errorMessage={form.message}
                                label={form.label}
                                type={form.type}
                                numberType={form.label === 'offset_qty' ? 'price' : 'default'}
                            />
                            {form.label === 'offset_qty' && <Unit>Ton</Unit>}
                        </InputBox>
                    )}

                    {/* SELECT FORM */}
                    {form.type === 'select' && (
                        <Select
                            register={register}
                            label={form.label}
                            required={form.required}
                            getValue={getValues(form.label)}
                            placeholder={form.message}
                            options={form.select}
                            error={!!errors[form.label]}
                            valueLength={watch(form.label) ? watch(form.label).length : 0}
                            errorMessage={form.message}
                        />
                    )}
                </FormBox>
            ))}
        </>
    );
}

const FormBox = styled.div`
    padding-bottom: 38px;
`;

const TitleBox = styled.div<{ type: string }>`
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 8px;
`;

const Title = styled.div`
    font-size: 14px;
    color: ${colors.Black100};
    font-weight: 500;

    span {
        color: ${colors.Red100};
        margin-right: 4px;
    }
`;

const InputBox = styled.div`
    position: relative;
`;

const Unit = styled.div`
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translate(0, -50%);
    font-weight: 700;
`;

export default BurnForm;
