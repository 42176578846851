import React, { useCallback } from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@styles/ui_palette';
import { useNavigate } from 'react-router-dom';
import { IBurnCard } from '@interface/common';

interface CardInterfaces {
    card: IBurnCard;
    size: number;
}

function BurnCard({ card, size }: CardInterfaces) {
    const navigate = useNavigate();

    const handleLink = useCallback((state: number) => {
        if (state === 0) {
            return;
        }

        navigate(`/burn/${card.id}`);
    }, []);

    return (
        <CradWrap size={size}>
            <CradContent onClick={() => handleLink(card.state)}>
                <Thumnail>
                    <ThumnailBox
                        bg={
                            card.more_info_url
                                ? card.more_info_url
                                : 'https://tantan-nft-prod-upload-s3.s3.amazonaws.com/nft_asset/more_info/offset_ca.png'
                        }
                    />

                    <DeemBox />
                </Thumnail>
                <ContentsBody>
                    {/* NFT 제목 */}
                    <MarketTitle>{card.ca}</MarketTitle>
                    {/* NFT 제목 */}
                    <MarketTitle>{card.cer_name}</MarketTitle>

                    <CardStateBox>
                        <CardState state={card.state}>
                            {card.state === 0 ? '처리중' : '완료'}
                        </CardState>
                        {/* 현재가 */}
                        <PriceBox>
                            상쇄한 수량
                            <Price>
                                {card.offset_qty.toLocaleString()}
                                <span>TON</span>
                            </Price>
                        </PriceBox>
                    </CardStateBox>
                </ContentsBody>
            </CradContent>
        </CradWrap>
    );
}

const CardStateBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
`;

const CardState = styled.div<{ state: number }>`
    width: 58px;
    height: 34px;
    background-color: ${(props) => (props.state === 0 ? colors.BlueGray500 : colors.Black100)};
    color: ${colors.White100};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
`;

const CradWrap = styled.div<{ size: number }>`
    padding: 0 14px;
    padding-bottom: 20px;
    width: calc(100% / ${(props) => props.size});
    position: relative;
`;

const Thumnail = styled.div`
    position: relative;
    height: 200px;
    overflow: hidden;
`;

const DeemBox = styled.div`
    width: 100%;
    height: 48px;
    background: linear-gradient(0deg, rgba(1, 10, 20, 0.4) 0%, rgba(1, 10, 20, 0) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
`;

const ThumnailBox = styled.div<{ bg: string }>`
    height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.BlueGray400};
    background-image: url(${(props) => `${props.bg}`});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    transition: all 0.2s linear;
`;

const CradContent = styled.div`
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 18px 0px, inset 0px -3px 0px rgba(0, 0, 0, 0.02);
    cursor: pointer;
    transition: all 0.4s ease;

    &:hover {
        /* box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 18px 0px, inset 0px -3px 0px rgba(0, 0, 0, 0.02); */
        /* transition: all .2s ease; */

        ${ThumnailBox} {
            transform: scale(1.1);
            transition: all 0.2s linear;
        }
    }
`;

const MarketTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${colors.Black100};
    display: -webkit-box;
    -webkit-line-clamp: 1; //원하는 라인수
    -webkit-box-orient: vertical;
    height: 18px;
    margin-top: 2px;
    overflow: hidden;
`;

const ContentsBody = styled.div`
    background-color: ${colors.White100};
    width: 100%;
    padding: 16px 12px;
    display: flex;
    flex-direction: column;
    position: relative;
`;

const PriceBox = styled.div`
    min-height: 19px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const Price = styled.div`
    font-size: 14px;
    color: ${colors.Black100};
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-left: 4px;

    span {
        margin-left: 2px;
    }
`;

export default BurnCard;
