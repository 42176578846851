import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@styles/ui_palette';
import { Helmet } from 'react-helmet-async';

// components
// import MainMainVisual from '@components/main/MainVisual';
import MainBanner from '@components/main/MainBanner';
import MainSecondSection from '@components/main/MainSecondSection';
import MainThirdSection from '@components/main/MainThirdSection';
import MainFourthSection from '@components/main/MainFourthSection';
import MainFifthSection from '@components/main/MainFifthSection';
import MainSixthSection from '@components/main/MainSixthSection';
import MainSeventhSection from '@components/main/MainSeventhSection';
import MainEighthSection from '@components/main/MainEighthSection';
// import MainCalcSection from '@components/main/MainCalcSection';
import MainCalcSection2 from '@components/main/MainCalcSection2';
import MainMarketPlace from '@components/main/MainMarketPlace';
// import MainCategory from '@components/main/MainCategory';
// import TanTanInformations from '@components/main/TanTanInformations';
// import MarketsInformations from '@components/main/MarketsInformations';
// import MarketGuide from '@components/main/MarketGuide';
import MainNoticeModals from '@components/main/MainNoticeModals';

function Main() {
    const [isNoticeModal, setNoticeModal] = useState<boolean>(false);
    const modalExpire = Date.now() + 21600000 * 4;

    const handleNoticeModal = (action: string) => {
        if (action === 'day') {
            localStorage.setItem('modalExpire', modalExpire.toString());
            setNoticeModal(false);
            return;
        }

        setNoticeModal(false);
    };

    useEffect(() => {
        const getModalExpire = localStorage.getItem('modalExpire');

        if (getModalExpire && Number(getModalExpire) > Date.now()) {
            setNoticeModal(false);
        } else {
            setNoticeModal(true);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>TANTAN MARKETPLACE | 탄탄 마켓플레이스</title>
                <meta property="og:site_name" content="TANTAN MARKETPLACE | 탄탄 마켓플레이스"/>
                <meta name='description' content='누구나 환경을 지킬 수 있도록, 작은 실천을 만드는 탄소배출권 플랫폼' />
                <meta property='og:url' content='https://tantanmarketplace.com' />
                <meta property='og:type' content='website' />
                <meta property="og:image" content="%PUBLIC_URL%/img_og_image.png"/>
                <meta property='og:title' content='TANTAN MARKETPLACE | 탄탄 마켓플레이스' />
                <meta property='og:description' content='누구나 환경을 지킬 수 있도록, 작은 실천을 만드는 탄소배출권 플랫폼' />
                <link rel="canonical" href="https://tantanmarketplace.com" />
            </Helmet>
            {/* 메인 비쥬얼 */}
            {/* <MainMainVisual /> */}

            <MainBanner />

            <SectionBackground>
                <MainSecondSection />

                <MainThirdSection />

                <MainFourthSection />

                <MainFifthSection />

                <MainSixthSection />

                <MainCalcSection2 />
                {/* <MainCalcSection2 /> */}

                {/* 마켓 플레이스 */}
                <MainMarketPlace />

                <MainSeventhSection />

                <MainEighthSection />

            </SectionBackground>

            {isNoticeModal && <MainNoticeModals handleNoticeModal={handleNoticeModal} />}
        </>
    );
}

const SectionBackground = styled.div`
    background-color: ${colors.White100};
    margin: 0 auto;
`;

export default Main;
