/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@styles/ui_palette';
import { Axios } from '@utils/api';
import { Store } from 'react-notifications-component';
import { IBasicCard, IBurnCard, IPaginationPrams } from '@interface/common';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { UserAddress } from '@recoil/auth';

// Components
import PageNavigation from '@components/common/ui/PageNavigation';
import BasicCard from '@components/common/BasicCard';
import BurnCard from '@components/common/BurnCard';
import CardSkeleton from '@components/common/ui/CardSkeleton';
import CategoryEmpty from '@components/common/CategoryEmpty';
import BurnModal from '@components/myPage/BurnModal';
import { useRecoilValue } from 'recoil';

interface ITab {
    title: string;
    unit: number;
    active: boolean;
    type: string;
}

interface ICreatorAssets {
    wallert_address: string;
}

const useTabData = () => {
    const userAddress = useRecoilValue(UserAddress);
    if (Number(userAddress.vip_gb) >= 1) {
        return [
            {
                title: '보유한 배출권',
                unit: 90,
                active: true,
                type: 'owner',
            },
            {
                title: '창작한 배출권',
                unit: 90,
                active: false,
                type: 'creator',
            },
            {
                title: '상쇄 배출권',
                unit: 90,
                active: false,
                type: 'burn',
            },
        ];
    }
    return [
        {
            title: '보유한 배출권',
            unit: 90,
            active: true,
            type: 'owner',
        },
        {
            title: '상쇄 배출권',
            unit: 90,
            active: false,
            type: 'burn',
        },
    ];
};

function CreatorAssets({ wallert_address }: ICreatorAssets) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [burnModal, setBurnModal] = useState<boolean>(false);
    const userAddress = useRecoilValue(UserAddress);
    const [burnInfo, setBurnInfo] = useState<{
        title: string;
        asset_id: number;
        asset_quantity: number;
        more_info_url: string;
    } | null>(null);
    const [nfts, setNft] = useState<any>([]);
    const [pagination, setPagination] = useState<number>(1);
    const [pagenationInfo, setPagenationInfo] = useState<IPaginationPrams | null>(null);
    const [loaded, setLoaded] = useState(false);
    const [tabItems, setTabItems] = useState<ITab[]>(useTabData());
    const [currentTab, setCurrentTab] = useState<ITab>({
        title: '보유한 배출권',
        unit: 90,
        active: true,
        type: 'owner',
    });

    const notiOption = {
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 1000,
        },
    };

    const handleModal = useCallback(() => {
        setBurnInfo(null);
        setBurnModal(false);
    }, [setBurnInfo, setBurnModal]);

    const handlePageChange = async (page: number) => {
        setPagination(page);

        window.scrollTo(0, 370);
        const newTabItems = tabItems.find((tabItem: ITab) => tabItem.active);

        if (newTabItems) {
            await navigate(`/mypage/${wallert_address}?tab=${newTabItems.type}&page=${page}`);

            if (newTabItems.type === 'burn') {
                loadBurnNFTs();
            } else {
                loadNFTs();
            }
        }
    };

    const handleChangeTab = async (item: ITab) => {
        const newTabItems = tabItems.map((tabItem: ITab): ITab => {
            const current = JSON.parse(JSON.stringify(tabItem));
            current.active = false;

            if (item.type === current.type) {
                current.active = true;

                setCurrentTab(item);
            }

            return current;
        });

        setTabItems(newTabItems);
        setPagination(1);
        setPagenationInfo(null);
        setNft([]);

        await navigate(`/mypage/${wallert_address}?tab=${item.type}&page=1`);

        if (item.type === 'burn') {
            loadBurnNFTs();
        } else {
            loadNFTs();
        }
    };

    const loadNFTs = async () => {
        setLoaded(false);
        const formData = new FormData();

        const getParams = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        formData.append('address', wallert_address);
        formData.append('co_gb', getParams.tab ? getParams.tab.toString() : 'owner');

        if (getParams.page) {
            setPagination(Number(getParams.page));
        }

        try {
            const { data, success } = await Axios(
                `coassetdetail?page=${getParams.page || 1}`,
                formData
            );

            setLoaded(true);
            if (success && data !== '') {
                const getData: IBasicCard[] | null = Object.values(data.coassetlist_data) || null;
                if (getData) {
                    await setPagenationInfo(data.pagination_info);
                    setNft(getData);
                }
            } else {
                setNft([]);
            }
        } catch (error) {
            setLoaded(true);
            setNft([]);
            Store.addNotification({
                ...notiOption,
                title: t('Notification.errorTitle'),
                message: t('Notification.notFoundMarketList'),
                type: 'danger',
                container: 'top-left',
                insert: 'top',
            });
        }
    };

    const loadBurnNFTs = async () => {
        setLoaded(false);
        // 로그인 토큰 가져오기
        const token = `Bearer ${localStorage.getItem('token')}` || '';
        const formData = new FormData();

        const getParams = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        formData.append('wallet_address', wallert_address);
        formData.append('co_gb', 'all');

        if (getParams.page) {
            setPagination(Number(getParams.page));
        }

        try {
            const { data, success } = await Axios(
                `offset_req_list?page=${getParams.page || 1}`,
                formData,
                token
            );

            console.log(data);

            setLoaded(true);
            if (success && data !== '') {
                const getData: IBurnCard[] | null =
                    Object.values(data.offset_req_list_data) || null;
                if (getData) {
                    await setPagenationInfo(data.pagination_info);
                    setNft(getData);
                }
            } else {
                setNft([]);
            }
        } catch (error) {
            setLoaded(true);
            setNft([]);
            console.log('111', error);
            Store.addNotification({
                ...notiOption,
                title: t('Notification.errorTitle'),
                message: t('Notification.notFoundMarketList'),
                type: 'danger',
                container: 'top-left',
                insert: 'top',
            });
        }
    };

    const handleBurnModal = useCallback(
        (info: {
            title: string;
            asset_id: number;
            asset_quantity: number;
            more_info_url: string;
        }) => {
            setBurnInfo(info);
            setBurnModal(true);
        },
        [setBurnModal, setBurnInfo]
    );

    useEffect(() => {
        const getParams = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        const newTabItems = tabItems.map((tabItem: ITab): ITab => {
            const current = JSON.parse(JSON.stringify(tabItem));
            current.active = false;

            if (!getParams.tab && current.type === 'owner') {
                current.active = true;
            }

            if (getParams.tab === current.type) {
                current.active = true;

                setCurrentTab(current);
            }

            return current;
        });

        setTabItems(newTabItems);

        if (getParams.tab === 'burn') {
            loadBurnNFTs();
        } else {
            loadNFTs();
        }
    }, []);

    return (
        <Container>
            <AssetsTab>
                {tabItems.map((item) => (
                    <TabItem
                        key={item.title}
                        active={item.active}
                        onClick={() => handleChangeTab(item)}
                    >
                        {item.title}
                    </TabItem>
                ))}
            </AssetsTab>

            {loaded && nfts.length > 0 && (
                <NFTCardWrapper>
                    {currentTab.type !== 'burn' &&
                        nfts.map((card: IBasicCard) => (
                            <CardWrap key={card.asset_id} size={4}>
                                <BasicCard card={card} size={1} />
                                {currentTab.type === 'owner' && (
                                    <BurnGroup>
                                        {userAddress.address !== card.creator_address &&
                                            card.asset_quantity > 0 && (
                                                <BurnButton
                                                    onClick={() =>
                                                        handleBurnModal({
                                                            title: card.market_name,
                                                            asset_id: card.asset_id,
                                                            asset_quantity: card.asset_quantity,
                                                            more_info_url: card.more_info_url || '',
                                                        })
                                                    }
                                                >
                                                    상쇄신청
                                                </BurnButton>
                                            )}
                                    </BurnGroup>
                                )}
                            </CardWrap>
                        ))}

                    {currentTab.type === 'burn' &&
                        nfts.map((card: IBurnCard) => (
                            <CardWrap key={card.asset_id} size={4}>
                                <BurnCard card={card} size={2} />
                            </CardWrap>
                        ))}
                </NFTCardWrapper>
            )}

            {!loaded && (
                <>
                    <CardSkeletonWrap>
                        <CardSkeleton />
                    </CardSkeletonWrap>
                    <CardSkeletonWrap>
                        <CardSkeleton />
                    </CardSkeletonWrap>
                </>
            )}

            {loaded && nfts.length === 0 && <CategoryEmpty title={t('MyPage.emptyMyNft')} />}

            {loaded && pagenationInfo && pagenationInfo.last_page > 1 && (
                <PageNavigation
                    activePage={pagination}
                    itemsCountPerPage={8}
                    totalItemsCount={pagenationInfo.total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                />
            )}

            {burnModal && burnInfo && burnInfo.asset_quantity > 0 && (
                <BurnModal
                    title={burnInfo.title}
                    asset_quantity={burnInfo.asset_quantity}
                    asset_id={burnInfo.asset_id.toString()}
                    handleModal={handleModal}
                    more_info_url={burnInfo.more_info_url}
                />
            )}
        </Container>
    );
}

const Container = styled.div`
    margin-top: 91px;
`;

const AssetsTab = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.BlueGray400};
`;

const CardSkeletonWrap = styled.div`
    margin-top: 24px;
`;

const TabItem = styled.div<{ active: boolean }>`
    flex-basis: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    color: ${colors.Black100};
    border-bottom: 2px solid ${(props) => (props.active ? colors.Black200 : 'transparent')};
    height: 60px;
    cursor: pointer;

    &:hover {
        background-color: ${colors.BlueGray300};
    }
`;

const NFTCardWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 60px;
    gap: 40px;
    margin-top: 40px;
`;

const CardWrap = styled.div<{ size: number }>`
    flex-basis: calc(100% / ${(props) => props.size});
`;

const BurnGroup = styled.div`
    margin-bottom: 40px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const BurnButton = styled.button`
    width: 90px;
    height: 36px;
    border-radius: 10px;
    border: 0;
    outline: 0;
    background-color: ${colors.Black100};
    color: ${colors.White100};
    cursor: pointer;
`;

export default CreatorAssets;
