export interface ICreateItems {
    minLength?: number;
    maxLength?: number;
    label?: string;
    type?: string;
    required?: boolean;
    icons?: string;
    defaultAddress?: string;
    id?: number;
    value?: string;
    name?: string;
}

export interface ICreateSelectItems {
    name: string;
    value: string;
}

export interface IProfileEditFormData {
    title: string;
    minLength: number;
    maxLength: number;
    label: string;
    type: string;
    message: string;
    required: boolean;
    items: ICreateItems[];
    select: ICreateSelectItems[];
    pattern: RegExp;
    patternMessage: string;
}

export interface Iburndata {
    title: string;
    minLength: number;
    maxLength: number;
    label: string;
    type: string;
    message: string;
    required: boolean;
    items: ICreateItems[];
    select: ICreateSelectItems[];
}

export const profileEditFormData = (t: any): IProfileEditFormData[] => [
    {
        title: t('ProfileEdit.nameInputTitle'),
        minLength: 1,
        maxLength: 20,
        label: 'user_name',
        type: 'text',
        message: t('ProfileEdit.nameInputMessage'),
        required: true,
        items: [],
        select: [],
        pattern: /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9_-]{2,20}$/,
        patternMessage: t('ProfileEdit.nameInputPlaceholder'),
    },
    {
        title: t('ProfileEdit.addressInputTitle'),
        minLength: 0,
        maxLength: 0,
        label: 'address',
        type: 'address',
        message: '',
        required: false,
        items: [],
        select: [],
        pattern: /$/,
        patternMessage: '',
    },
    {
        title: t('ProfileEdit.descriptionInputTitle'),
        minLength: 0,
        maxLength: 500,
        label: 'user_bio',
        type: 'textarea',
        message: t('ProfileEdit.descriptionInputPlaceholder'),
        required: false,
        items: [],
        select: [],
        pattern: /$/,
        patternMessage: '',
    },

    {
        title: t('ProfileEdit.emailInputTitle'),
        minLength: 0,
        maxLength: 50,
        label: 'user_email',
        type: 'text',
        message: t('ProfileEdit.emailInputMessage'),
        required: true,
        items: [],
        select: [],
        pattern:
            /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i,
        patternMessage: t('ProfileEdit.emailInputPlaceholder'),
    },
    {
        title: '배송지 정보',
        minLength: 1,
        maxLength: 20,
        label: 'user_certification',
        type: 'button',
        message: t('ProfileEdit.nameInputMessage'),
        required: true,
        items: [],
        select: [],
        pattern: /$/,
        patternMessage: t('ProfileEdit.nameInputPlaceholder'),
    },
    {
        title: '배송지 정보 입력',
        minLength: 0,
        maxLength: 50,
        label: 'shipping_addr2',
        type: 'shipping_address',
        message: '상세주소를 입력해주세요.',
        required: true,
        items: [],
        select: [],
        pattern: /$/,
        patternMessage: '상세주소를 입력해주세요.',
    },
    {
        title: '마케팅 및 서비스 관련 정보, 이용약관, 개인정보 수신 동의.',
        minLength: 0,
        maxLength: 0,
        label: 'm_rec_gb',
        type: 'checkbox',
        message: '',
        required: false,
        items: [
            {
                id: 1,
                value: '',
                name: t('ProfileEdit.marketingInputAgree'),
            },
        ],
        select: [],
        pattern: /$/,
        patternMessage: '',
    },
];

export const burnFormData = (): Iburndata[] => [
    {
        title: '상쇄 수량',
        minLength: 1,
        maxLength: 7,
        label: 'offset_qty',
        type: 'number',
        message: '상쇄수량을 입력 해주세요.',
        required: true,
        items: [],
        select: [],
    },
    {
        title: '상쇄 목적',
        minLength: 0,
        maxLength: 0,
        label: 'offset_use',
        type: 'select',
        message: '상쇄목적을 선택해주세요.',
        required: true,
        items: [],
        select: [
            {
                name: '기후변화 대응에 동참',
                value: '기후변화 대응에 동참',
            },
            {
                name: '내가 배출한 온실가스 상쇄',
                value: '내가 배출한 온실가스 상쇄',
            },
            {
                name: '여행으로 발생한 온실가스 상쇄',
                value: '여행으로 발생한 온실가스 상쇄',
            },
            {
                name: '가족이 배출한 온실가스를 상쇄',
                value: '가족이 배출한 온실가스를 상쇄',
            },
            {
                name: '회사가 배출한 온실가스를 상쇄',
                value: '회사가 배출한 온실가스를 상쇄',
            },
        ],
    },
    {
        title: '이름/사업자명',
        minLength: 1,
        maxLength: 100,
        label: 'offset_name',
        type: 'text',
        message: '이름/사업자명을 입력해주세요.',
        required: true,
        items: [],
        select: [],
    },
    {
        title: '생년월일/사업자 번호',
        minLength: 1,
        maxLength: 100,
        label: 'offset_juno',
        type: 'number',
        message: '생년월일/사업자 번호를 입력해주세요.',
        required: true,
        items: [],
        select: [],
    },
];
