import styled from '@emotion/styled/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef, useEffect } from 'react';
import example1 from '../../../public/img/main/example_Australia.png';
import example2 from '../../../public/img/main/example_Somalia.png';
import example3 from '../../../public/img/main/example_Texas.png';
import example4 from '../../../public/img/main/example_Honduras.png';
import example5 from '../../../public/img/main/example_India.png';
import example6 from '../../../public/img/main/example_Pakistan.png';

gsap.registerPlugin(ScrollTrigger);

function MainThirdSection() {
    const Title1Ref = useRef(null);
    const Title2Ref = useRef(null);
    const Text1Ref = useRef(null);
    const Img1Ref = useRef(null);
    const Img2Ref = useRef(null);
    useEffect(() => {
        gsap.to(
            Title1Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                scrollTrigger: {
                    trigger:'.third-title',
                    start: '30% bottom', 
                },
            },
        );

        gsap.to(
            Title2Ref.current, 
            0.7, 
            { 
                transform: 'translateY(0px)', 
                opacity:1,
                delay: 0.3, 
                scrollTrigger: {
                    trigger:'.third-title',
                    start: '30% bottom', 
                },
            }
        );

        gsap.to(
            Text1Ref.current, 
            0.7, 
            { 
                opacity:1,
                delay:0.65,
                scrollTrigger: {
                    trigger:'.third-text',
                    start: '30% bottom', 
                },
            }
        );

        gsap.to(
            Img1Ref.current, 
            0.5, 
            { 
                transform: 'translateX(0px)', 
                opacity:1,
                delay:0.3,
                scrollTrigger: {
                    trigger:'.example-line1',
                    start: '10% bottom', 
                },
            }
        );

        gsap.to(
            Img2Ref.current, 
            0.5, 
            { 
                transform: 'translateX(0px)', 
                opacity:1,
                delay: 0.4,
                scrollTrigger: {
                    trigger:'.example-line2',
                    start: '10% bottom', 
                },
            }
        );
    }, []);
    return (
        <Container>
            <Section>
                <TitleWrapper className="third-title">
                    <TitleBox>
                        <Title ref={Title1Ref} >지구가 우리에게</Title>
                    </TitleBox>
                    <TitleBox>
                        <Title ref={Title2Ref} >경고하고 있어요</Title>
                    </TitleBox>
                </TitleWrapper>
                <Description ref={Text1Ref} className='third-text'>
                    지구를 더럽히는 대가는 점점 더 가혹해지고 있어요.<br />
                    빙산이 녹아 땅을 삼키고, 건조해진 공기가 숲을 불태우는 등<br />
                    앞으로도 우리에게 크고 다양한 피해를 가져올 거에요.
                </Description>
                <ExampleWrapper>
                    <ExampleLine ref={Img1Ref} className="example-line1">
                        <ExampleBox className='Example1' >
                            <ExampleImg src={example1} alt="Australia" />
                            <ExampleStartText>호주에서는<br />산불이 일어나</ExampleStartText>
                            <ExampleResultText>
                                야생동물 5억마리 사망<br/>
                                산림 5천만 에이커 전소
                            </ExampleResultText>
                        </ExampleBox>
                        <ExampleBox>
                            <ExampleImg src={example2} alt="Somalia" />
                            <ExampleStartText>소말리아에서는<br />가뭄으로 인하여</ExampleStartText>
                            <ExampleResultText>43,000명 사망<br />절반은 5세 미만 아동</ExampleResultText>
                        </ExampleBox>
                        <ExampleBox>
                            <ExampleImg src={example3} alt="Texas" />
                            <ExampleStartText>미국 텍사스에서는<br />폭설이 내리며</ExampleStartText>
                            <ExampleResultText>210명 사망<br /><span>평년대비 -22°C 온도 저하</span></ExampleResultText>
                        </ExampleBox>  
                    </ExampleLine>
                    <ExampleLine ref={Img2Ref} className="example-line2">
                        <ExampleBox>
                            <ExampleImg src={example4} alt="Houduras" />
                            <ExampleStartText>온두라스에서는<br />허리케인이 발생하여</ExampleStartText>
                            <ExampleResultText>수재민 92,000명 발생<br />주택 62,000채 손실</ExampleResultText>
                        </ExampleBox>
                        <ExampleBox>
                            <ExampleImg src={example5} alt="India" />
                            <ExampleStartText>인도에서는<br />폭염이 발생하여</ExampleStartText>
                            <ExampleResultText>최고온도 50°C 상승<br />열사병 환자 20명 사망</ExampleResultText>
                        </ExampleBox>
                        <ExampleBox>
                            <ExampleImg src={example6} alt="Pakistan" />
                            <ExampleStartText>파키스탄에서는<br />홍수가 일어나며</ExampleStartText>
                            <ExampleResultText>1,700명 사망<br />이재민 3,300만명 발생</ExampleResultText>
                        </ExampleBox>
                    </ExampleLine>
                </ExampleWrapper>
            </Section>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    padding: 160px 0;
    background-color: var(--default-text);
`;

const Section = styled.div`
    width: 1280px;
    margin: 0 auto;
    position: relative;
`;

const TitleWrapper = styled.div`
    display: block;
`;

const TitleBox = styled.div`
    display: block;
    overflow: hidden;
`;

const Title = styled.h2`
    color:#ffffff;
    text-align: left;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.4;
    opacity: 0;
    transform: translateY(60px);
`;

const Description = styled.p`
    font-size: 20px;
    font-weight: 200;
    color: var(--gray-scale4);
    margin-top: 32px;
    line-height: 1.5;
    opacity: 0;
`;

const ExampleWrapper = styled.div`
    margin-top: 160px;
`;

const ExampleLine = styled.div`
    display: flex;
    align-items: center;
    opacity: 0;
    &:first-of-type{
        justify-content: flex-end;
        margin-bottom: 50px;
        transform: translateX(100px);
    }
    &:first-of-type > div{
        margin-left: 50px;
    }
    &:last-of-type{
        transform: translateX(-100px);
    }
    &:last-of-type > div{
        margin-right: 50px;
    }
`;

const ExampleBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 260px;
    height: 300px;
    border-radius: 13px;
    overflow: hidden;
    position: relative;
    padding: 30px;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
    
`;

const ExampleImg = styled.img`
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

const ExampleStartText = styled.p`

    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.3;
    z-index: 2;
`;

const ExampleResultText = styled.p`
    font-size: 21px;
    font-weight: 600;
    color: #ffffff;
    line-height: 1.3;
    white-space:nowrap;
    z-index: 3;
    & > span{
        letter-spacing: -1.5px;
    }
`;




export default MainThirdSection;
