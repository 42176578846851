import { loadPaymentWidget } from '@tosspayments/payment-widget-sdk';

const customerKey = '-oX_VxaJPm0DKzIDfm_Ev';
const clientKey = process.env.REACT_APP_TOSS_PAYMENTS_Client_Key?.toString() || '';

interface ITossOrder {
    id: string;
    totalPrice: number;
    asset_name: string;
    user_name: string;
}

export async function tossPaymentsOrder({ id, totalPrice, asset_name, user_name }: ITossOrder) {
    const paymentWidget = await loadPaymentWidget(clientKey, customerKey);

    paymentWidget.renderPaymentMethods(
        '#payment-widget',
        { value: totalPrice },
        // 렌더링하고 싶은 결제 UI의 variantKey
        // 아래 variantKey는 문서용 테스트키와 연동되어 있습니다. 멀티 UI를 직접 만들고 싶다면 계약이 필요해요.
        // https://docs.tosspayments.com/guides/payment-widget/admin#멀티-결제-ui
        { variantKey: 'DEFAULT' }
    );

    paymentWidget.requestPayment({
        orderId: id,
        orderName: asset_name,
        customerName: user_name,
        // amount: totalPrice,
        successUrl: `${window.location.origin}/toss-payments-complete`,
        failUrl: `${window.location.origin}`,
    });
}
